import Cookies from "js-cookie";

const API_URL = "https://bf-api.solutioweb.com.br/operadores";

export const getOperadorById = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar operador:", error);
    throw error;
  }
};

export const getOperadoresByUser = async () => {
  try {
    const token = Cookies.get("TokenBrasao") || "";

    const response = await fetch(`${API_URL}/listaPorUsuario/${token}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar operadores por usuário:", error);
    throw error;
  }
};

export const getOperadores = async (idCliente = null, search = "") => {
  try {
    const url = search
      ? `${API_URL}/lista?idCliente=${idCliente}&search=${encodeURIComponent(search)}`
      : `${API_URL}/lista?idCliente=${idCliente}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar lista de operadores:", error);
    throw error;
  }
};

export const createOperador = async (operador: any) => {
  try {
    const token = Cookies.get("TokenBrasao") || "";
    const formData = new FormData();
    formData.append(
      "idCliente",
      operador.idCliente !== undefined ? operador.idCliente : null
    );
    formData.append("nome", operador.nome);
    formData.append("email", operador.email);
    formData.append("telefone", operador.telefone);
    formData.append("cidades", operador.cidades);
    formData.append("produtos", operador.produtos);
    formData.append("idUser", token);

    const response = await fetch(API_URL, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao criar operador:", error);
    throw error;
  }
};

export const updateOperador = async (operador: any) => {
  try {
    const token = Cookies.get("TokenBrasao") || "";

    const formData = new FormData();
    formData.append(
      "idCliente",
      operador.idCliente !== undefined ? operador.idCliente : null
    );
    formData.append("nome", operador.nome);
    formData.append("email", operador.email);
    formData.append("telefone", operador.telefone);
    formData.append("cidades", operador.cidades);
    formData.append("produtos", operador.produtos);
    formData.append("idUser", token);

    const response = await fetch(`${API_URL}/${operador.id}`, {
      method: "PUT",
      body: formData,
    });

    if (!response.ok) {
      return { error: "Houve uma falha. Tente mais tarde." };
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao atualizar operador:", error);
    throw error;
  }
};

export const deleteOperador = async (id: number) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();
    formData.append("id", id.toString());

    const response = await fetch(`${API_URL}/delete`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao deletar operador:", error);
    throw error;
  }
};
