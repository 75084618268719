import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { Slide, ToastContainer, toast } from "react-toastify";
import { getVendedores } from "../services/vendedoresService";
import { getFazendas } from "../services/fazendaService";
import { getInsumos } from "../services/insumosService";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Button } from "react-bootstrap";
import { IoCartOutline } from "react-icons/io5";

export interface Vendedor {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  empresa: string;
  insumos: string;
}

// Interface para os insumos dentro do JSON de cada vendedor (filhos)
interface InsumoVendedor {
  value: string;
  label: string;
  preco: string;
  categoria?: string; // corresponde ao id do insumo pai
}

// Interface para os insumos retornados pela API (pais)
interface Insumo {
  id: number;
  descricao: string | null;
  categoria: string | null;
  idPai: number | null;
  imagem: string | null;
}

const ComprasPage: React.FC = () => {
  const [vendedores, setVendedores] = useState<Vendedor[]>([]);
  const [insumosList, setInsumosList] = useState<Insumo[]>([]);
  const navigate = useNavigate();

  // Busca os vendedores
  const fetchVendedores = async (search = "") => {
    try {
      const data = await getVendedores(search);
      setVendedores(data);
    } catch (error) {
      console.error("Erro ao buscar vendedores:", error);
    }
  };

  // Busca os insumos (pais) da API e garante que seja um array
  const fetchInsumos = async () => {
    try {
      const data = await getInsumos();
      //const data = response?.data || [];
      console.log(data);
      setInsumosList(data);
    } catch (error) {
      console.error("Erro ao buscar insumos:", error);
      setInsumosList([]);
    }
  };

  // Exemplo de fetch de fazendas, se necessário para outras ações
  const fetchFazendas = async (search = "") => {
    try {
      const fazendas = await getFazendas(search);
      const idUser = Cookies.get("TokenBrasao");
      const filteredFazendas = fazendas.filter(
        (fazenda: any) => fazenda.idUser === idUser
      );
      // Se necessário, configure opções para um Select
    } catch (error) {
      console.error("Erro ao buscar fazendas:", error);
    }
  };

  // Toast para alertas
  const [alerta, setAlerta] = useState("");
  const notify = () => toast.info(alerta);

  useEffect(() => {
    fetchVendedores();
    fetchInsumos();
    fetchFazendas();
  }, []);

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  return (
    <>
      <HeaderComponent />
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a
                      onClick={() => navigate("/")}
                      className="breadcrumb-item"
                    >
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Compras</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="card">
                <div className="row">
                  {vendedores.map((vendedor) => {
                    let insumosParsed: InsumoVendedor[] = [];
                    if (vendedor.insumos) {
                      try {
                        insumosParsed = JSON.parse(
                          vendedor.insumos
                        ) as InsumoVendedor[];
                      } catch (error) {
                        console.error(
                          "Erro ao parsear insumos do vendedor",
                          vendedor.id,
                          error
                        );
                      }
                    }
                    return insumosParsed.map((insumo, index) => {
                      //console.log(insumo);
                      //console.log(insumosList);
                      // Se existir "categoria", ela representa o id do insumo pai
                      const parentInsumo = insumo.value
                        ? insumosList.find(
                            (item) => item.id.toString() === insumo.value
                          )
                        : null;

                      return (
                        <div
                          key={`${vendedor.id}-${index}`}
                          className="col-md-4 mb-3"
                        >
                          <div className="card-body">
                            <div className="card">
                              {parentInsumo && parentInsumo.imagem ? (
                                <img
                                  src={parentInsumo.imagem}
                                  className="card-img-top"
                                  alt={
                                    parentInsumo.descricao ||
                                    parentInsumo.categoria ||
                                    "Imagem do insumo"
                                  }
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div
                                  className="card-img-top d-flex align-items-center justify-content-center"
                                  style={{
                                    height: "200px",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  Sem imagem
                                </div>
                              )}
                              <div className="card-body">
                                {/* Exibe o nome do insumo pai */}
                                <h5 className="card-title mb-0">
                                  {parentInsumo
                                    ? parentInsumo.descricao ||
                                      parentInsumo.categoria ||
                                      "Sem nome"
                                    : "Sem pai"}
                                </h5>
                                {/* Exibe os dados do insumo filho */}
                                <p className="card-text">
                                  Insumo: {insumo.label}
                                </p>
                                <p className="card-text">
                                  <b>R${insumo.preco}</b>
                                </p>

                                <Button
                                  variant="primary"
                                  style={{
                                    float: "left",
                                    marginLeft: "0",
                                    display: "flex",
                                  }}
                                >
                                  {" "}
                                  Adicionar ao carrinho{" "}
                                  <IoCartOutline
                                    style={{
                                      marginTop: "3px",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    });
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
};

export default ComprasPage;
