import Cookies from "js-cookie";

const API_URL = "https://bf-api.solutioweb.com.br/fazendas";

export const getFazendaById = async (id: number) => {
  try {
    const token = Cookies.get("TokenBrasao");
    const response = await fetch(API_URL + "/" + token + "/" + id);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching fazendas:", error);
    throw error;
  }
};

export const getFazendas = async (search = "") => {
  try {
    const token = Cookies.get("TokenBrasao");
    const url = search
      ? `${API_URL}/${token}?search=${encodeURIComponent(search)}`
      : API_URL + "/" + token;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching fazendas:", error);
    throw error;
  }
};

export const createFazenda = async (fazenda: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();

    formData.append("nome", fazenda.nome);

    formData.append("status", fazenda.status);
    formData.append("arrendada", fazenda.arrendada);
    formData.append("valor_hectare", fazenda.valor_hectare);
    formData.append("area_total_hectare", fazenda.area_total_hectare);
    formData.append(
      "area_preservacao_permanente",
      fazenda.area_preservacao_permanente
    );
    formData.append("area_reserva_ambiental", fazenda.area_reserva_ambiental);
    formData.append("area_util", fazenda.area_util);
    formData.append("uf", fazenda.uf);
    formData.append("cidade", fazenda.cidade);

    if (token) {
      formData.append("idUser", token);
    }

    const response = await fetch(API_URL, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      return { error: "Houve uma falha. Tente mais tarde." };
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error creating fazenda:", error);
    throw error;
  }
};

export const updateFazenda = async (fazenda: any) => {
  try {
    const formData = new FormData();
    formData.append("id", fazenda.id);
    formData.append("nome", fazenda.nome);
    formData.append("status", fazenda.status);
    formData.append("arrendada", fazenda.arrendada);
    formData.append("valor_hectare", fazenda.valor_hectare);
    formData.append("area_total_hectare", fazenda.area_total_hectare);
    formData.append(
      "area_preservacao_permanente",
      fazenda.area_preservacao_permanente
    );
    formData.append("area_reserva_ambiental", fazenda.area_reserva_ambiental);
    formData.append("area_util", fazenda.area_util);
    formData.append("uf", fazenda.uf);
    formData.append("cidade", fazenda.cidade);

    const token = Cookies.get("TokenBrasao");

    if (token) {
      formData.append("idUser", token);
    }

    const response = await fetch(API_URL + "/" + fazenda.id, {
      method: "PUT",
      body: formData,
    });

    if (!response.ok) {
      return { error: "Houve uma falha. Tente mais tarde." };
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error creating fazenda:", error);
    throw error;
  }
};
