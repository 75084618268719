import React, { useState, useEffect, useRef } from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline, IoSearch } from "react-icons/io5";
import { Modal, Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import {
  getCategorias,
  getCategoriaById,
  createCategoria,
  updateCategoria,
  uploadCategoriaImage,
  uploadProdutoImage,
  createSubcategoriaNivel1,
  createSubcategoriaNivel2,
  createProduto,
  // Se já tiver um updateProduto, importe aqui também:
  // updateProduto,
  getSubcategoriasNivel1PorCategoria,
  getSubcategoriasNivel2PorCategoria,
  getProdutosPorCategoria,
} from "../services/categoriasService";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Cropper } from "react-cropper";

export interface Categoria {
  id: number;
  descricao: string;
}

interface SubcategoriaNivel1 {
  id: number;
  nome: string;
}

interface SubcategoriaNivel2 {
  id: number;
  nome: string;
  subcategoria_nivel1_id: number;
}

interface Produto {
  id: number;
  nome: string;
  subcategoria_nivel2_id: number;
  imagem: "";
  // Se a API retornar imagem, inclua aqui: imagem?: string;
}

const CategoriasPage: React.FC = () => {
  const navigate = useNavigate();

  // ----------------------------------------------------------------
  // ESTADOS E REF PARA CATEGORIA
  // ----------------------------------------------------------------
  const [show, setShow] = useState(false);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [newCategoria, setNewCategoria] = useState<{
    id: any;
    descricao: string;
    imagem: string;
  }>({
    id: null,
    descricao: "",
    imagem: "",
  });
  const initialCategoriaState = { id: null, descricao: "", imagem: "" };
  const [isEditing, setIsEditing] = useState(false); // Se está editando CATEGORIA (ou não)
  const [alerta, setAlerta] = useState("");
  const [search, setSearch] = useState("");

  // Upload/crop da imagem de CATEGORIA
  const [upImgCategoria, setUpImgCategoria] = useState<string | null>(null);
  const cropperCategoriaRef = useRef<HTMLImageElement>(null);

  // ----------------------------------------------------------------
  // ESTADOS SUBCATEGORIAS
  // ----------------------------------------------------------------
  const [subcategoriasNivel1, setSubcategoriasNivel1] = useState<
    SubcategoriaNivel1[]
  >([]);
  const [subcategoriasNivel2, setSubcategoriasNivel2] = useState<
    SubcategoriaNivel2[]
  >([]);

  // ----------------------------------------------------------------
  // ESTADOS E REF PARA PRODUTO
  // ----------------------------------------------------------------
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [newProduto, setNewProduto] = useState({
    id: 0,
    nome: "",
    subcategoriaNivel2Id: "",
    categoriaId: null as number | null,
    // Se quiser armazenar imagem inicial do produto (para edição), inclua aqui:
    // imagem: ""
  });

  // Upload/crop da imagem de PRODUTO
  const [upImgProduto, setUpImgProduto] = useState<string | null>(null);
  const cropperProdutoRef = useRef<HTMLImageElement>(null);

  // ----------------------------------------------------------------
  // FORMULÁRIOS DE SUBCATEGORIA
  // ----------------------------------------------------------------
  const [newSubcategoriaNivel1, setNewSubcategoriaNivel1] = useState({
    nome: "",
    categoriaId: null as number | null,
  });

  const [newSubcategoriaNivel2, setNewSubcategoriaNivel2] = useState({
    nome: "",
    subcategoriaNivel1Id: "",
    categoriaId: null as number | null,
  });

  // ----------------------------------------------------------------
  // FECHAR MODAL E LIMPAR TUDO
  // ----------------------------------------------------------------
  const handleClose = () => {
    setShow(false);

    // CATEGORIA
    setNewCategoria(initialCategoriaState);
    setUpImgCategoria(null);

    // PRODUTO
    setNewProduto({
      id: 0,
      nome: "",
      subcategoriaNivel2Id: "",
      categoriaId: null,
      // imagem: "" se houver
    });
    setUpImgProduto(null);

    // SUBCATEGORIAS
    setNewSubcategoriaNivel1({ nome: "", categoriaId: null });
    setNewSubcategoriaNivel2({
      nome: "",
      subcategoriaNivel1Id: "",
      categoriaId: null,
    });

    setIsEditing(false);
  };

  // ----------------------------------------------------------------
  // ABRIR MODAL PARA CRIAR (SEM EDIÇÃO)
  // ----------------------------------------------------------------
  const add = () => {
    setNewCategoria(initialCategoriaState);
    setIsEditing(false);
    setShow(true);

    // Limpa subcategorias e produtos
    setSubcategoriasNivel1([]);
    setSubcategoriasNivel2([]);
    setProdutos([]);
  };

  // ----------------------------------------------------------------
  // EDITAR CATEGORIA (CARREGA TUDO E ABRE MODAL)
  // ----------------------------------------------------------------
  const edit = async (id: number) => {
    try {
      const categoria = await getCategoriaById(id);
      setNewCategoria({
        id: categoria.id,
        descricao: categoria.descricao,
        imagem: categoria.imagem,
      });
      setIsEditing(true);
      setShow(true);

      // Carrega subcats e produtos
      const subsN1 = await getSubcategoriasNivel1PorCategoria(categoria.id);
      setSubcategoriasNivel1(subsN1);
      const subsN2 = await getSubcategoriasNivel2PorCategoria(categoria.id);
      setSubcategoriasNivel2(subsN2);
      const prods = await getProdutosPorCategoria(categoria.id);
      setProdutos(prods);

      // Atualiza estados
      setNewSubcategoriaNivel1((prev) => ({
        ...prev,
        categoriaId: categoria.id,
      }));
      setNewSubcategoriaNivel2((prev) => ({
        ...prev,
        categoriaId: categoria.id,
      }));
      setNewProduto((prev) => ({ ...prev, categoriaId: categoria.id }));
    } catch (error) {
      console.error("Erro ao buscar categoria:", error);
    }
  };

  // ----------------------------------------------------------------
  // NOTIFICAÇÕES
  // ----------------------------------------------------------------
  const notify = () => toast.info(alerta);
  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  // ----------------------------------------------------------------
  // CARREGAR LISTA DE CATEGORIAS
  // ----------------------------------------------------------------
  const fetchCategorias = async (searchValue = "") => {
    try {
      const data = await getCategorias(searchValue);
      setCategorias(data || []);
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
    }
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === "" || search.trim().length >= 3) {
      fetchCategorias(search.trim());
    } else {
      setAlerta("O termo de pesquisa deve ter pelo menos 3 caracteres.");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === "") {
      fetchCategorias("");
    }
  };

  useEffect(() => {
    fetchCategorias();
  }, []);

  // ----------------------------------------------------------------
  // CAPTURAR MUDANÇAS DO FORM DE CATEGORIA
  // ----------------------------------------------------------------
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setNewCategoria({ ...newCategoria, [name]: value });
  };

  // ----------------------------------------------------------------
  // UPLOAD/CROP DA CATEGORIA
  // ----------------------------------------------------------------
  const onSelectFileCategoria = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setUpImgCategoria(reader.result as string);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // ----------------------------------------------------------------
  // SALVAR/ATUALIZAR CATEGORIA
  // ----------------------------------------------------------------
  const handleSubmitCategoria = async () => {
    setAlerta("");
    if (!newCategoria.descricao) {
      setAlerta("O campo Descrição é obrigatório.");
      return;
    }
    try {
      let result: any;
      if (isEditing) {
        result = await updateCategoria(newCategoria);
      } else {
        result = await createCategoria(newCategoria);
      }

      // Se houver imagem nova para crop
      if (upImgCategoria && cropperCategoriaRef.current) {
        const cropper = (cropperCategoriaRef.current as any).cropper;
        const canvas = cropper.getCroppedCanvas({ width: 300, height: 300 });
        if (canvas) {
          const base64Image = canvas.toDataURL("image/jpeg");
          const base64Data = base64Image.replace(
            /^data:image\/jpeg;base64,/,
            ""
          );
          await uploadCategoriaImage(result.data?.id || result.id, base64Data);
        }
      }

      fetchCategorias();

      if (result.status === "error") {
        setAlerta(result.messages[0]);
      } else {
        if (isEditing) {
          setCategorias((prev) =>
            prev.map((cat) => (cat.id === newCategoria.id ? result.data : cat))
          );
        } else {
          setCategorias((prev) => [...prev, result]);
        }
        handleClose();
        setAlerta(
          isEditing
            ? "Categoria atualizada com sucesso."
            : "Categoria cadastrada com sucesso."
        );
      }
    } catch (error) {
      setAlerta("Não foi possível realizar esta operação.");
    }
  };

  // ----------------------------------------------------------------
  // CRUD DE SUBCATEGORIAS
  // ----------------------------------------------------------------
  const handleSaveSubcategoriaNivel1 = async () => {
    if (!newSubcategoriaNivel1.nome) {
      setAlerta("Preencha o nome da Subcategoria (Nível 1).");
      return;
    }
    try {
      await createSubcategoriaNivel1(newSubcategoriaNivel1);
      setAlerta("Subcategoria (Nível 1) cadastrada com sucesso.");

      // Atualiza a lista
      const subsN1 = await getSubcategoriasNivel1PorCategoria(newCategoria.id);
      setSubcategoriasNivel1(subsN1);
      // Reseta
      setNewSubcategoriaNivel1({ nome: "", categoriaId: newCategoria.id });
    } catch (error) {
      setAlerta("Erro ao cadastrar Subcategoria (Nível 1).");
    }
  };

  const handleSaveSubcategoriaNivel2 = async () => {
    if (
      !newSubcategoriaNivel2.nome ||
      !newSubcategoriaNivel2.subcategoriaNivel1Id
    ) {
      setAlerta("Preencha o nome e selecione a subcategoria de nível 1.");
      return;
    }
    try {
      await createSubcategoriaNivel2(newSubcategoriaNivel2);
      setAlerta("Subcategoria (Nível 2) cadastrada com sucesso.");

      // Atualiza lista
      const subsN2 = await getSubcategoriasNivel2PorCategoria(newCategoria.id);
      setSubcategoriasNivel2(subsN2);
    } catch (error) {
      setAlerta("Erro ao cadastrar Subcategoria (Nível 2).");
    }
  };

  // ----------------------------------------------------------------
  // UPLOAD/CROP DE PRODUTO
  // ----------------------------------------------------------------
  const onSelectFileProduto = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setUpImgProduto(reader.result as string);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // ----------------------------------------------------------------
  // SALVAR/ATUALIZAR PRODUTO (MESMA LÓGICA DA CATEGORIA)
  // ----------------------------------------------------------------
  const handleSaveProduto = async () => {
    setAlerta("");

    // Validação básica
    if (!newProduto.nome || !newProduto.subcategoriaNivel2Id) {
      setAlerta("Preencha o nome e selecione a subcategoria de nível 2.");
      return;
    }

    try {
      let result: any;
      if (isEditing) {
        // Se já existir a rota de updateProduto, você faria:
        // result = await updateProduto(newProduto);
        // senão, mantenha só createProduto mesmo
        // (ou crie outro estado booleano, ex: isEditingProduto, para saber se está editando produto ou categoria)
        result = await createProduto(newProduto);
      } else {
        result = await createProduto(newProduto);
      }

      // Se tiver imagem selecionada para o produto
      if (upImgProduto && cropperProdutoRef.current) {
        const cropper = (cropperProdutoRef.current as any).cropper;
        const canvas = cropper.getCroppedCanvas({ width: 300, height: 300 });
        if (canvas) {
          const base64Image = canvas.toDataURL("image/jpeg");
          const base64Data = base64Image.replace(
            /^data:image\/jpeg;base64,/,
            ""
          );
          await uploadProdutoImage(result.data?.id || result.id, base64Data);
        }
      }

      // Atualiza lista local
      const prods = await getProdutosPorCategoria(newCategoria.id);
      setProdutos(prods);

      if (result.status === "error") {
        setAlerta(result.messages[0]);
      } else {
        if (isEditing) {
          // Se estivesse editando de fato:
          // setProdutos((prev) =>
          //   prev.map((p) => (p.id === newProduto.id ? result.data : p))
          // );
        } else {
          // Se é novo produto
          setProdutos((prev) => [...prev, result]);
        }
        handleClose();
        setAlerta(
          isEditing
            ? "Produto atualizado com sucesso."
            : "Produto cadastrado com sucesso."
        );
      }
    } catch (error) {
      setAlerta("Não foi possível realizar esta operação.");
    }
  };

  // ----------------------------------------------------------------
  // ESTILOS
  // ----------------------------------------------------------------
  const style = document.createElement("style");
  style.innerHTML = `
    .modal-dialog{
      min-width: 90%;
    }
  `;
  document.head.appendChild(style);

  // ----------------------------------------------------------------
  // RENDER
  // ----------------------------------------------------------------
  return (
    <>
      <HeaderComponent />
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a
                      onClick={() => navigate("/")}
                      className="breadcrumb-item"
                    >
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Categorias</span>
                  </div>
                  <a
                    href="#"
                    className="header-elements-toggle text-body d-lg-none"
                  >
                    <i className="icon-more"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="content">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <Button className="bt-1-ico" variant="indigo" onClick={add}>
                      <IoAddCircleOutline className="m-0-auto fs-2em" />
                    </Button>
                    <span className="fs-1-5em pl-2">Categorias</span>
                  </h5>
                  <div className="float-right">
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por nome..."
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className="fs-1-7em" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>#</th>
                          <th>Descrição</th>
                          <th style={{ width: "80px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {categorias
                          .filter((cat) => cat !== undefined)
                          .map((cat) => (
                            <tr key={cat.id}>
                              <td>{cat.id}</td>
                              <td>{cat.descricao}</td>
                              <td>
                                <Button
                                  variant="info"
                                  size="sm"
                                  onClick={() => edit(cat.id)}
                                >
                                  <MdEdit />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL PRINCIPAL */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isEditing ? "Editar categoria" : "Nova categoria"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="categoria" id="categoria-tabs">
            {/* ---------------------- ABA: CATEGORIA ---------------------- */}
            <Tab eventKey="categoria" title="Categoria">
              <Row className="mt-2">
                <Col lg={12}>
                  <div className="form-group">
                    <label>Nome:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome"
                      name="descricao"
                      value={newCategoria.descricao}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>

                {/* Mostrar imagem atual (se houver) */}
                <Col lg={12}>
                  {newCategoria.imagem && (
                    <img
                      src={`${newCategoria.imagem}?t=${Date.now()}`}
                      alt={newCategoria.descricao}
                      className="img-fluid"
                    />
                  )}
                </Col>

                {/* Upload da imagem de categoria */}
                <Col lg={12} className="mt-2">
                  <div className="form-group">
                    <label>Selecione ou altere a imagem (opcional):</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onSelectFileCategoria}
                    />
                  </div>
                </Col>

                {/* Cropper da categoria */}
                {upImgCategoria && (
                  <Col lg={12} className="mt-2">
                    <Cropper
                      src={upImgCategoria}
                      style={{ width: "100%", height: "100%" }}
                      aspectRatio={1}
                      autoCropArea={1}
                      viewMode={1}
                      dragMode="move"
                      background={false}
                      checkOrientation={false}
                      responsive={true}
                      cropBoxResizable={false}
                      cropBoxMovable={false}
                      minCropBoxWidth={300}
                      minCropBoxHeight={300}
                      ref={cropperCategoriaRef}
                    />
                  </Col>
                )}
              </Row>
            </Tab>

            {/* ---------------------- ABA: SUBCATEGORIA NÍVEL 1 ---------------------- */}
            <Tab eventKey="subcategoria1" title="Subcategoria (Nível 1)">
              <Row className="mt-2">
                <Col lg={12}>
                  <div className="form-group">
                    <label>Nome da Subcategoria (Nível 1):</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome da subcategoria"
                      value={newSubcategoriaNivel1.nome}
                      onChange={(e) =>
                        setNewSubcategoriaNivel1({
                          ...newSubcategoriaNivel1,
                          nome: e.target.value,
                          categoriaId: newCategoria.id,
                        })
                      }
                    />
                  </div>
                </Col>

                <Col lg={12} className="mt-2 pb-3">
                  <Button
                    variant="primary"
                    onClick={handleSaveSubcategoriaNivel1}
                  >
                    Salvar Subcategoria (Nível 1)
                  </Button>
                </Col>

                <Col lg={12} className="mt-2">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subcategoriasNivel1.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.nome}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Tab>

            {/* ---------------------- ABA: SUBCATEGORIA NÍVEL 2 ---------------------- */}
            <Tab eventKey="subcategoria2" title="Subcategoria (Nível 2)">
              <Row className="mt-2">
                <Col lg={12}>
                  <div className="form-group">
                    <label>Nome da Subcategoria (Nível 2):</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome da subcategoria"
                      value={newSubcategoriaNivel2.nome}
                      onChange={(e) =>
                        setNewSubcategoriaNivel2({
                          ...newSubcategoriaNivel2,
                          nome: e.target.value,
                          categoriaId: newCategoria.id,
                        })
                      }
                    />
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="form-group">
                    <label>
                      Selecione a Subcategoria (Nível 1) pertencente:
                    </label>
                    <select
                      className="form-control"
                      value={newSubcategoriaNivel2.subcategoriaNivel1Id || ""}
                      onChange={(e) =>
                        setNewSubcategoriaNivel2({
                          ...newSubcategoriaNivel2,
                          subcategoriaNivel1Id: e.target.value,
                        })
                      }
                    >
                      <option value="">Selecione</option>
                      {subcategoriasNivel1.map((sub1) => (
                        <option key={sub1.id} value={sub1.id}>
                          {sub1.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>

                <Col lg={12} className="mt-2 pb-3">
                  <Button
                    variant="primary"
                    onClick={handleSaveSubcategoriaNivel2}
                  >
                    Salvar Subcategoria (Nível 2)
                  </Button>
                </Col>

                <Col lg={12} className="mt-2">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>Subcat. Nível 1</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subcategoriasNivel2.map((item) => {
                          const subN1 = subcategoriasNivel1.find(
                            (s) => s.id === item.subcategoria_nivel1_id
                          );
                          return (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>{item.nome}</td>
                              <td>
                                {subN1
                                  ? subN1.nome
                                  : item.subcategoria_nivel1_id}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Tab>

            {/* ---------------------- ABA: PRODUTO ---------------------- */}
            <Tab eventKey="produto" title="Produtos">
              <Row className="mt-2">
                {/* Nome do produto */}
                <Col lg={12}>
                  <div className="form-group">
                    <label>Nome do Produto:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome do produto"
                      value={newProduto.nome}
                      onChange={(e) =>
                        setNewProduto({ ...newProduto, nome: e.target.value })
                      }
                    />
                  </div>
                </Col>

                {/* Subcategoria (Nível 2) */}
                <Col lg={12}>
                  <div className="form-group">
                    <label>
                      Selecione a Subcategoria (Nível 2) pertencente:
                    </label>
                    <select
                      className="form-control"
                      value={newProduto.subcategoriaNivel2Id || ""}
                      onChange={(e) =>
                        setNewProduto({
                          ...newProduto,
                          subcategoriaNivel2Id: e.target.value,
                        })
                      }
                    >
                      <option value="">Selecione</option>
                      {subcategoriasNivel2.map((sub2) => (
                        <option key={sub2.id} value={sub2.id}>
                          {sub2.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>

                {/* Se quiser exibir a imagem do produto ao editar, crie algo assim:
                {newProduto.imagem && (
                  <Col lg={12}>
                    <img
                      src={`${newProduto.imagem}?t=${Date.now()}`}
                      alt={newProduto.nome}
                      className="img-fluid"
                    />
                  </Col>
                )} 
                */}

                {/* Upload da imagem de PRODUTO */}
                <Col lg={12} className="mt-2">
                  <label>Selecione ou altere a imagem (opcional):</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onSelectFileProduto}
                  />
                </Col>

                {/* Cropper do produto */}
                {upImgProduto && (
                  <Col lg={12} className="mt-2">
                    <Cropper
                      src={upImgProduto}
                      style={{ width: "100%", height: "100%" }}
                      aspectRatio={1}
                      autoCropArea={1}
                      viewMode={1}
                      dragMode="move"
                      background={false}
                      checkOrientation={false}
                      responsive={true}
                      cropBoxResizable={false}
                      cropBoxMovable={false}
                      minCropBoxWidth={300}
                      minCropBoxHeight={300}
                      ref={cropperProdutoRef}
                    />
                  </Col>
                )}

                {/* Botão de salvar (ou atualizar) produto */}
                <Col lg={12} className="pt-3 pb-4">
                  <Button variant="primary" onClick={handleSaveProduto}>
                    {isEditing ? "Atualizar Produto" : "Salvar Produto"}
                  </Button>
                </Col>

                {/* Tabela de produtos */}
                <Col lg={12} className="mt-2">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>Subcat. Nível 2</th>
                          <th>Imagem</th>
                        </tr>
                      </thead>
                      <tbody>
                        {produtos.map((item) => {
                          const subNivel2 = subcategoriasNivel2.find(
                            (sub) => sub.id === item.subcategoria_nivel2_id
                          );
                          return (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>{item.nome}</td>
                              <td>
                                {subNivel2
                                  ? subNivel2.nome
                                  : item.subcategoria_nivel2_id}
                              </td>
                              <td>
                                <img
                                  src={item.imagem}
                                  style={{ width: "150px" }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Modal.Body>

        {/* Footer: aqui só estamos salvando a CATEGORIA (pois está no "mundo" da categoria).
            O produto tem o botão próprio lá na aba dele. Se quiser duplicar a mesma ideia
            para o produto, crie outro button ou outro modal específico. */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmitCategoria}>
            {isEditing ? "Atualizar Categoria" : "Salvar Categoria"}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
};

export default CategoriasPage;
