import React, { useEffect, useState } from "react";

interface InputdecimalRegexComponentProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void;
}

const formatCurrency = (value: string): string => {
  if (!value) return "";

  let cleanValue = value.replace(/\D/g, "");

  cleanValue = cleanValue.replace(/^0+/, "") || "0";

  if (cleanValue.length === 1) {
    cleanValue = "0" + cleanValue;
  }

  let integerPart = cleanValue.slice(0, -2) || "0";
  let decimalPart = cleanValue.slice(-2);

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return `${integerPart},${decimalPart}`;
};

const InputdecimalRegexComponent: React.FC<InputdecimalRegexComponentProps> = ({
  onChange,
  value = "",
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(formatCurrency(value.toString()));
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCurrency(e.target.value);
    setInputValue(formattedValue);

    if (onChange) {
      onChange(e, formattedValue);
    }
  };

  return (
    <input
      {...props}
      className="form-control"
      type="text"
      value={inputValue}
      onChange={handleInputChange}
    />
  );
};

export default InputdecimalRegexComponent;
