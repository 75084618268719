import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";

interface InputDecimal2ComponentProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputDecimal2Component: React.FC<InputDecimal2ComponentProps> = (
  props
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const $input = $(inputRef.current);

      // Aplicar máscara monetária
      $input.mask("###.###.###,00", {
        reverse: true,
        onKeyPress: () => {
          const currentValue = $input.val() as string;

          // Evitar ponto isolado ao inserir valores menores que 1000
          if (currentValue && currentValue.startsWith(".")) {
            $input.val(currentValue.replace(/^\./, ""));
          }
        },
      });

      // Adicionar o prefixo "R$" no valor sempre que necessário
      const updatePrefix = () => {
        const currentValue = $input.val() as string;
        if (currentValue && !/^R\$/.test(currentValue)) {
          $input.val(`R$ ${currentValue}`);
        }
      };

      const handleInput = (event: JQuery.TriggeredEvent<HTMLInputElement>) => {
        updatePrefix(); // Garantir que o prefixo esteja presente

        const target = event.target as HTMLInputElement;
        const value = target.value.replace(/^R\$ /, "");
        const name = props.name || "";

        const changeEvent = {
          ...event,
          target: { ...target, name, value },
          currentTarget: target,
          preventDefault: event.preventDefault.bind(event),
          stopPropagation: event.stopPropagation.bind(event),
          nativeEvent: event.originalEvent,
        } as unknown as React.ChangeEvent<HTMLInputElement>;

        props.onChange && props.onChange(changeEvent);
      };

      // Adicionar eventos
      $input.on("input", handleInput);
      $input.on("blur", updatePrefix); // Prefixo será adicionado ao perder o foco

      return () => {
        $input.off("input", handleInput);
        $input.off("blur", updatePrefix);
      };
    }
  }, [props]);

  return (
    <input className="form-control" type="text" ref={inputRef} {...props} />
  );
};

export default InputDecimal2Component;
