import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline, IoSearch } from "react-icons/io5";
import { FaPlus, FaTrash, FaCheck } from "react-icons/fa";
import { Modal, Button, Col, Row, Table } from "react-bootstrap";
import { getInsumos } from "./../services/insumosService";
import InputdecimalRegexComponentProps from "../components/InputdecimalRegexComponent";
import { MdEdit } from "react-icons/md";
import { getProdutosPorCategoria } from "../services/categoriasService";
import {
  getVendedores,
  getVendedorById,
  createVendedor,
  updateVendedor,
} from "../services/vendedoresService";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

export interface Vendedor {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  empresa: string;
  insumos: any[];
}

export interface Insumo {
  id: number;
  descricao: string;
}

interface TabelaInsumo {
  key: string;
  id: string;
  descricao: string;
  preco: string;
  categoria: string;
}

const VendedoresPage: React.FC = () => {
  const handleClose = () => setShow(false);
  const notify = () => toast.info(alerta);
  const [insumos, setInsumos] = useState<Insumo[]>([]);
  const [vendedores, setVendedores] = useState<Vendedor[]>([]);
  const [newVendedor, setNewVendedor] = useState<Vendedor>({
    id: 0,
    nome: "",
    email: "",
    telefone: "",
    empresa: "",
    insumos: [],
  });
  const [selectedInsumos, setSelectedInsumos] = useState<
    { value: string; label: string }[]
  >([]);
  const [insumoOptions, setInsumoOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [insumoCategoriaOptions, setInsumoCategoriaOptions] = useState<
    { value: string; label: string; idPai: string }[]
  >([]);
  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [alerta, setAlerta] = useState("");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [tabelaInsumos, setTabelaInsumos] = useState<TabelaInsumo[]>([]);

  const style = document.createElement("style");
  style.innerHTML = `
    .modal-dialog{
      min-width: 90%;
    }
  `;
  document.head.appendChild(style);

  useEffect(() => {
    fetchVendedores();
    fetchInsumos();
    fetchInsumosCategorias();
  }, []);

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  const fetchVendedores = async (search = "") => {
    try {
      const data = await getVendedores(search);
      setVendedores(data);
    } catch (error) {
      console.error("Erro ao buscar vendedores:", error);
    }
  };

  const fetchInsumos = async () => {
    try {
      const response = await getProdutosPorCategoria("");

      const formattedInsumos = response
        /* .filter(
          (insumo: {
            id: number;
            nome: string | null;
            categoria: string | null;
          }) => insumo.categoria === null
        ) */
        .map((insumo: { id: number; nome: string }) => ({
          value: insumo.id.toString(),
          label: insumo.nome,
        }));

      setInsumoOptions(formattedInsumos);
    } catch (error) {
      console.error("Erro ao buscar insumos:", error);
    }
  };

  const fetchInsumosCategorias = async () => {
    try {
      const response = await getInsumos();

      const formattedInsumos = response
        .filter(
          (insumo: {
            id: number;
            descricao: string | null;
            categoria: string | null;
            idPai: number | null;
          }) => insumo.idPai !== null && insumo.categoria !== null
        )
        .map((insumo: any) => ({
          value: insumo.id.toString(),
          label: insumo.categoria,
          idPai: insumo.idPai.toString(),
        }));

      setInsumoCategoriaOptions(formattedInsumos);
    } catch (error) {
      console.error("Erro ao buscar insumos categorias:", error);
    }
  };

  const editVendedor = async (id: number) => {
    try {
      const vendedor = await getVendedorById(id);

      const insumosArray = Array.isArray(vendedor.insumos)
        ? vendedor.insumos
        : JSON.parse(vendedor.insumos);

      const insumosSelecionados = insumosArray.map((insumo: any) => ({
        value: insumo.value,
        label: insumo.label,
      }));

      const insumosTabela = insumosArray.map((insumo: any, index: number) => ({
        key: `${insumo.value}-${index}`,
        id: insumo.value,
        descricao: insumo.label,
        preco: insumo.preco,
        categoria: insumo.categoria ? insumo.categoria.toString() : "",
      }));

      setNewVendedor({
        ...vendedor,
        insumos: insumosArray,
      });
      setSelectedInsumos(insumosSelecionados);
      setTabelaInsumos(insumosTabela);
      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error("Erro ao buscar vendedor:", error);
    }
  };

  const handleInsumoChange = (selectedOptions: any) => {
    const options = Array.isArray(selectedOptions)
      ? selectedOptions
      : selectedOptions
        ? [selectedOptions]
        : [];

    setNewVendedor((prevState) => ({
      ...prevState,
      insumos: options,
    }));
    setSelectedInsumos(options);

    if (options.length > 0) {
      const novoInsumo = options[options.length - 1];
      setTabelaInsumos((prevTabela) => [
        ...prevTabela,
        {
          key: `${novoInsumo.value}-${prevTabela.length}`,
          id: novoInsumo.value,
          descricao: novoInsumo.label,
          preco: "0",
          categoria: "", // valor padrão para categoria
        },
      ]);
    }
  };

  const handleCategoriaChange = (id: string, selectedOption: any) => {
    setTabelaInsumos((prev) =>
      prev.map((insumo) =>
        insumo.id === id
          ? { ...insumo, categoria: selectedOption ? selectedOption.value : "" }
          : insumo
      )
    );
  };

  const handleExcluirInsumo = (id: string) => {
    setTabelaInsumos((prev) => prev.filter((insumo) => insumo.id !== id));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "telefone") {
      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");

      setNewVendedor({ ...newVendedor, [name]: maskedValue });
    } else {
      setNewVendedor({ ...newVendedor, [name]: value });
    }
  };

  const add = () => {
    setNewVendedor({
      id: 0,
      nome: "",
      email: "",
      telefone: "",
      empresa: "",
      insumos: [],
    });

    setSelectedInsumos([]);
    setTabelaInsumos([]);

    setIsEditing(false);
    setShow(true);
  };

  const handleSubmit = async () => {
    setAlerta("");

    if (newVendedor.nome === "" || newVendedor.email === "") {
      setAlerta("Os campos Nome e E-mail são obrigatórios.");
      return;
    }

    try {
      const insumosComPreco = tabelaInsumos.map((insumo) => ({
        value: insumo.id,
        label: insumo.descricao,
        preco: insumo.preco,
        categoria: insumo.categoria,
      }));

      const vendedorParaSalvar = {
        ...newVendedor,
        insumos: insumosComPreco,
      };

      let result;
      if (isEditing) {
        result = await updateVendedor(vendedorParaSalvar);
      } else {
        result = await createVendedor(vendedorParaSalvar);
      }

      if (result.status === "error") {
        setAlerta(result.messages[0]);
      } else {
        fetchVendedores("");
        setShow(false);
        setAlerta(
          isEditing
            ? "Cadastro atualizado com sucesso."
            : "Cadastro realizado com sucesso."
        );
      }
    } catch (error) {
      setAlerta("Erro ao salvar vendedor.");
    }
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === "" || search.trim().length >= 3) {
      fetchVendedores(search.trim());
    } else {
      setAlerta("O termo de pesquisa deve ter pelo menos 3 caracteres.");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === "") {
      fetchVendedores("");
    }
  };

  const handlePrecoChange = (id: string, novoPreco: any) => {
    setTabelaInsumos((prev) =>
      prev.map((insumo) =>
        insumo.id === id ? { ...insumo, preco: novoPreco } : insumo
      )
    );
  };

  return (
    <>
      <HeaderComponent />
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a
                      onClick={() => navigate("/")}
                      className="breadcrumb-item"
                    >
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">
                      Fornecedores / Insumos
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <Button className="bt-1-ico" variant="indigo" onClick={add}>
                      <IoAddCircleOutline className="m-0-auto fs-2em" />
                    </Button>
                    <span className="fs-1-5em pl-2">
                      Fornecedores / Insumos
                    </span>
                  </h5>
                  <div className="float-right">
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por nome, e-mail ou empresa"
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className="fs-1-7em" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card-body">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Telefone</th>
                        <th>Empresa</th>
                        <th style={{ width: "100px", textAlign: "center" }}>
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vendedores.map((vendedor) => (
                        <tr key={vendedor.id}>
                          <td>{vendedor.id}</td>
                          <td>{vendedor.nome}</td>
                          <td>{vendedor.email}</td>
                          <td>{vendedor.telefone}</td>
                          <td>{vendedor.empresa}</td>
                          <td style={{ textAlign: "center" }}>
                            <Button
                              variant="info"
                              size="sm"
                              onClick={() => editVendedor(vendedor.id)}
                            >
                              <MdEdit />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isEditing
              ? "Editar Fornecedor / Insumo"
              : "Novo Fornecedor / Insumo"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label>Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  name="nome"
                  value={newVendedor.nome}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={newVendedor.email}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label>Telefone:</label>
                <input
                  type="text"
                  className="form-control"
                  name="telefone"
                  value={newVendedor.telefone}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label>Empresa:</label>
                <input
                  type="text"
                  className="form-control"
                  name="empresa"
                  value={newVendedor.empresa}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <label>Insumos:</label>
                <Select
                  options={insumoOptions}
                  placeholder="Selecione"
                  value={selectedInsumos}
                  onChange={handleInsumoChange}
                />
              </div>
            </Col>
            <Col lg={12}>
              {tabelaInsumos.length > 0 && (
                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Insumo</th>
                      <th>Preço (R$)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelaInsumos.map((insumo) => {
                      const optionsFiltradas = insumoCategoriaOptions.filter(
                        (option) => option.idPai === insumo.id
                      );
                      return (
                        <tr key={insumo.id}>
                          <td>{insumo.descricao}</td>
                          <td>
                            <InputdecimalRegexComponentProps
                              type="text"
                              placeholder=""
                              value={insumo.preco || ""}
                              name="preco"
                              style={{ color: "#333", width: "100px" }}
                              onKeyUp={(e: any) =>
                                handlePrecoChange(insumo.id, e.target.value)
                              }
                            />
                          </td>
                          <td style={{ width: "80px" }}>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleExcluirInsumo(insumo.id)}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEditing ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={Slide}
      />
    </>
  );
};

export default VendedoresPage;
