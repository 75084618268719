import React from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { MdAttachMoney } from "react-icons/md";
import { FaCalendarPlus } from "react-icons/fa6";
import { FaCalendarAlt } from "react-icons/fa";
import { FaCalendarMinus } from "react-icons/fa6";
import { FaHandHoldingDollar } from "react-icons/fa6";

// =============== REACT BOOTSTRAP ===============
import { Container, Button, Row, Col, Card, Table } from "react-bootstrap";

// =============== REACT-CHARTJS-2 ===============
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement, // Para doughnut/pie
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

// Registrando os componentes necessários do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// ============ DONUT (Área colhida) ============ //
const areaData = {
  labels: ["Área colhida"],
  datasets: [
    {
      label: "Área colhida",
      data: [104, 100],
      backgroundColor: ["#4CAF50", "#d2d2d2"],
      borderWidth: 0,
    },
  ],
};
const areaOptions = {
  cutout: "70%",
  responsive: true,
  plugins: {
    legend: { display: false },
    title: { display: false },
  },
};

// ============ BAR HORIZONTAL (Produção) ============ //
const producaoData = {
  labels: ["Produzido", "Restante"],
  datasets: [
    {
      label: "Produção (sc 50kg)",
      data: [40737.38, 19262.62],
      backgroundColor: ["#4CAF50", "#d2d2d2"],
    },
  ],
};
const producaoOptions = {
  indexAxis: "y" as const, // barras horizontais
  responsive: true,
  scales: {
    x: {
      max: 60000, // Valor total para dimensionar a barra
    },
  },
  plugins: {
    legend: { display: false },
    title: { display: false },
  },
};

// ============ BAR HORIZONTAL (Produtividade) ============ //
const produtividadeData = {
  labels: ["Produzido", "Restante"],
  datasets: [
    {
      label: "Produtividade (sc 50kg/ha)",
      data: [150.72, 49.28],
      backgroundColor: ["#4CAF50", "#d2d2d2"],
    },
  ],
};
const produtividadeOptions = {
  indexAxis: "y" as const,
  responsive: true,
  scales: {
    x: {
      max: 200,
    },
  },
  plugins: {
    legend: { display: false },
    title: { display: false },
  },
};

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderComponent />
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="content-inner">
            {/* BREADCRUMB / HEADER */}

            <div className="content">
              <div className="card p-2 pt-4">
                {/* Usando Container, Row e Col do React Bootstrap */}
                <Container fluid>
                  <Row>
                    <Col xs={12} md={4}>
                      <Card className="text-center">
                        <Card.Body>
                          <Card.Title>Área colhida</Card.Title>
                          <div
                            style={{
                              width: 150,
                              height: 150,
                              margin: "0 auto",
                            }}
                          >
                            <Doughnut data={areaData} options={areaOptions} />
                          </div>
                          <p style={{ fontWeight: "bold", marginTop: 10 }}>
                            52%
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* DUAS BARRAS HORIZONTAIS (Produção) */}
                    <Col xs={12} md={4}>
                      <Card className="text-center">
                        <Card.Body>
                          <Card.Title>Produção</Card.Title>
                          <p style={{ fontWeight: "bold" }}>
                            40.737,38 sc 50kg
                          </p>
                          <div
                            style={{
                              width: 250,
                              height: 100,
                              margin: "0 auto",
                            }}
                          >
                            <Bar
                              data={producaoData}
                              options={producaoOptions}
                            />
                          </div>

                          <Button
                            variant="light mt-3"
                            style={{ float: "right", marginLeft: "7px" }}
                          >
                            Ver indicador
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>

                    {/* DUAS BARRAS HORIZONTAIS (Produtividade) */}
                    <Col xs={12} md={4}>
                      <Card className="text-center">
                        <Card.Body>
                          <Card.Title>Produtividade</Card.Title>
                          <p style={{ fontWeight: "bold" }}>
                            150,72 sc 50kg/ha
                          </p>
                          <div
                            style={{
                              width: 250,
                              height: 100,
                              margin: "0 auto",
                            }}
                          >
                            <Bar
                              data={produtividadeData}
                              options={produtividadeOptions}
                            />
                          </div>
                          <Button
                            variant="light mt-3"
                            style={{ float: "right", marginLeft: "7px" }}
                          >
                            Ver indicador
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  {/* ====== SEÇÃO SUPERIOR: 6 CARDS ====== */}
                  <Row className="mb-12">
                    <Col md={6} lg={4} className="mb-3">
                      <Card>
                        <Card.Body className="d-flex p-2">
                          <div
                            style={{
                              width: "20%",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                            className="bg-primary tex-white"
                          >
                            <MdAttachMoney
                              style={{
                                width: "100%",
                                height: "50px",
                                color: "white",
                              }}
                            />
                          </div>
                          <div style={{ width: "80%", padding: "10px 20px" }}>
                            SALDO BANCÁRIO HOJE
                            <br />
                            <strong>R$ 0,00</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} lg={4} className="mb-3">
                      <Card>
                        <Card.Body className="d-flex p-2">
                          <div
                            style={{
                              width: "20%",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                            className="bg-success tex-white"
                          >
                            <FaCalendarPlus
                              style={{
                                width: "100%",
                                height: "50px",
                                color: "white",
                              }}
                            />
                          </div>
                          <div style={{ width: "80%", padding: "10px 20px" }}>
                            A RECEBER NOS PRÓXIMOS 30 DIAS
                            <br />
                            <strong>R$ 0,00</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} lg={4} className="mb-3">
                      <Card>
                        <Card.Body className="d-flex p-2">
                          <div
                            style={{
                              width: "20%",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                            className="bg-warning tex-white"
                          >
                            <FaHandHoldingDollar
                              style={{
                                width: "100%",
                                height: "50px",
                                color: "white",
                              }}
                            />
                          </div>
                          <div style={{ width: "80%", padding: "10px 20px" }}>
                            A RECEBER ATRASADOS
                            <br />
                            <strong>R$ 0,00</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-12">
                    <Col md={6} lg={4} className="mb-3">
                      <Card>
                        <Card.Body className="d-flex p-2">
                          <div
                            style={{
                              width: "20%",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                            className="bg-pink tex-white"
                          >
                            <FaCalendarAlt
                              style={{
                                width: "100%",
                                height: "50px",
                                color: "white",
                              }}
                            />
                          </div>
                          <div style={{ width: "80%", padding: "10px 20px" }}>
                            PROJETADO EM 30 DIAS
                            <br />
                            <strong>R$ 0,00</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} lg={4} className="mb-3">
                      <Card>
                        <Card.Body className="d-flex p-2">
                          <div
                            style={{
                              width: "20%",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                            className="bg-info tex-white"
                          >
                            <FaCalendarMinus
                              style={{
                                width: "100%",
                                height: "50px",
                                color: "white",
                              }}
                            />
                          </div>
                          <div style={{ width: "80%", padding: "10px 20px" }}>
                            A PAGAR NOS PRÓXIMOS 30 DIAS
                            <br />
                            <strong>R$ 0,00</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} lg={4} className="mb-3">
                      <Card>
                        <Card.Body className="d-flex p-2">
                          <div
                            style={{
                              width: "20%",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                            className="bg-danger tex-white"
                          >
                            <FaHandHoldingDollar
                              style={{
                                width: "100%",
                                height: "50px",
                                color: "white",
                              }}
                            />
                          </div>
                          <div style={{ width: "80%", padding: "10px 20px" }}>
                            A PAGAR ATRASADOS
                            <br />
                            <strong>R$ 0,00</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  {/* ====== SEGUNDA SEÇÃO: TABELAS LADO A LADO ====== */}
                  <Row className="mb-4">
                    <Col md={6}>
                      <Card>
                        <Card.Body>
                          <h4>Lançamentos Vencidos</h4>
                          <Table bordered striped>
                            <thead>
                              <tr>
                                <th>
                                  <strong>Período</strong>
                                </th>
                                <th>
                                  <strong>A Receber</strong>
                                </th>
                                <th>
                                  <strong>A Pagar</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Até 7 dias atrás</td>
                                <td>R$ 0,00</td>
                                <td>R$ 0,00</td>
                              </tr>
                              <tr>
                                <td>Até 14 dias atrás</td>
                                <td>R$ 0,00</td>
                                <td>R$ 0,00</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card>
                        <Card.Body>
                          <h4>Lançamentos a Vencer</h4>
                          <Table bordered striped>
                            <thead>
                              <tr>
                                <th>
                                  <strong>Período</strong>
                                </th>
                                <th>
                                  <strong>A Receber</strong>
                                </th>
                                <th>
                                  <strong>A Pagar</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Hoje</td>
                                <td>R$ 0,00</td>
                                <td>R$ 0,00</td>
                              </tr>
                              <tr>
                                <td>Até 7 dias</td>
                                <td>R$ 0,00</td>
                                <td>R$ 0,00</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
