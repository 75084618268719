import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Card,
  Table,
  Badge,
  Stack,
  CardBody,
} from "react-bootstrap";
import Select from "react-select";
import InputDecimal2Component from "../components/Inputdecimal2Component";
import InputDecimal0Component from "../components/Inputdecimal0Component";
import { getFazendas } from "./../services/fazendaService";
import { getFilteredTalhoes } from "./../services/talhaoService";
import { getProdutos, getSubprodutos } from "./../services/produtosService";
import { deleteVenda, getVendaById } from "./../services/vendaService";
import { MdEdit } from "react-icons/md";
import {
  createVenda,
  createVendaDireta,
  getVendasByUser,
  updateVenda,
} from "./../services/vendaService";
import { getClientesByUser } from "./../services/clientesService";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { Slide, ToastContainer, toast } from "react-toastify";
import { IoAddCircleOutline, IoAddOutline } from "react-icons/io5";
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import { IoMdTrash } from "react-icons/io";
import {
  getClientes,
  getClienteById,
  createCliente,
  updateCliente,
} from "../services/clientesService";

export interface Fazenda {
  id: number;
  nome: string;
}

export interface Produto {
  id: number;
  descricao: string;
}

export interface Subproduto {
  id: number;
  descricao: string;
}

type Talhao = {
  idProducao: number;
  nome: string;
  volumeDisponivel: number;
  volumeTipo: string;
  volumeUtilizado?: number;
  total_quantidade_kilograma: number;
  data_colheita: string;
};

interface VendaState {
  idFazenda: number;
  produto: string;
  subProduto: string;
  safra: string;
  volumeVenda: number;
  dataCarregamentoDe: string;
  dataCarregamentoAte: string;
  dataPagamento: string;
  valorVenda: string;
  armazenamento: string;
  destino: string;
  lote: string;
  talhoes: { value: string; label: string }[];
  cliente: number;
}

interface VendaEditState {
  id: number;
  idFazenda: number;
  produto: string;
  subProduto: string;
  safra: string;
  volumeVenda: number;
  dataCarregamentoDe: string;
  dataCarregamentoAte: string;
  dataPagamento: string;
  valorVenda: string;
  armazenamento: string;
  destino: string;
  lote: string;
  talhoes: { value: string; label: string }[];
  cliente: number;
}

interface VendaDiretaState {
  idFazenda: number;
  produto: string;
  subProduto: string;
  safra: string;
  volumeVenda: number;
  dataCarregamentoDe: string;
  dataCarregamentoAte: string;
  dataPagamento: string;
  valorVenda: string;
  armazenamento: string;
  destino: string;
  lote: string;
  talhoes: { value: string; label: string }[];
  cliente: number;
}

export interface ListaVendas {
  id: number;
  idFazenda: number;
  produto: string;
  sub_produto: string;
  safra: string;
  volume_venda: string;
  valor_venda_bruto: string;
  destino: string;
  data_cadastro: string;
  data_alteracao: string;
  fazenda_nome: string;
  nome_usuario: string;
  data_pagamento: string;
  data_carregamento_de: string;
  data_carregamento_ate: string;
  lote: string;
  unidade: string;
  negociacao: number;
  realizada: number;
}

export interface ListaClientes {
  id: number;
  razao_social: string;
}

export interface Cliente {
  id: number;
  nome_fantasia: string;
  razao_social: string;
  cpf_cnpj: string;
  inscricao_estadual: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento?: string;
  bairro: string;
  cidade: string;
  uf: string;
  telefone_fixo?: string;
  celular: string;
  email: string;
  dados_bancarios?: string;
  banco?: string;
  agencia?: string;
  conta?: string;
  titular_conta?: string;
  cpf_cnpj_titular?: string;
  tipo_pessoa: string;
  tipo_cliente: number;
  tipo_fornecedor: number;
  tipo_transportador: number;
  antt?: string;
  maxlength?: number;
}

const VendasPage: React.FC = () => {
  const navigate = useNavigate();
  const [listaVendas, setListaVendas] = useState<ListaVendas[]>([]);
  const [listaClientes, setListaClientes] = useState<ListaClientes[]>([]);
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [fazendas, setFazendas] = useState<Fazenda[]>([]);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [subprodutos, setSubprodutos] = useState<Subproduto[]>([]);
  const [activeTab, setActiveTab] = useState("novaVenda");
  const [btEditaModalVenda, setEditaModalVenda] = useState(false);
  const [btEfetivaModalVenda, setEfetivaModalVenda] = useState(false);
  const [desfazerModalVenda, setDesfazerModalVenda] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [disabledInscricao, setDisabledInscricao] = useState(true);
  const [filteredMunicipios, setFilteredMunicipios] = useState<any[]>([]);
  const [ufComboSel, setUfComboSel] = useState("");
  const [dataColheitaMaisFutura, setDataColheitaMaisFutura] = useState(null);

  const normalizeData = (cliente: Cliente): Cliente => {
    return {
      ...cliente,
      complemento: cliente.complemento || "",
      telefone_fixo: cliente.telefone_fixo || "",
      dados_bancarios: cliente.dados_bancarios || "",
      banco: cliente.banco || "",
      agencia: cliente.agencia || "",
      conta: cliente.conta || "",
      titular_conta: cliente.titular_conta || "",
      cpf_cnpj_titular: cliente.cpf_cnpj_titular || "",
      antt: cliente.antt || "",
    };
  };

  const handleCep = (e: any) => {
    let cleanedValue = e.target.value.replace(/\D/g, "");
    let maskedValue = cleanedValue.replace(/(\d{5})(\d)/, "$1-$2");
    setNewCliente({ ...newCliente, ["cep"]: maskedValue });
  };

  const [inscricaoPlaceholder, setInscricaoPlaceholder] =
    useState("Selecione o Estado");

  const fetchAddress = async (cep: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (!data.erro) {
        setNewCliente((prevCliente) => ({
          ...prevCliente,
          endereco: data.logradouro,
          complemento: data.complemento,
          bairro: data.bairro,
          cidade: data.localidade,
        }));
      } else {
        setAlerta("CEP não encontrado.");
      }
    } catch (error) {
      setAlerta("Erro ao buscar endereço. Tente novamente.");
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    let mascara = false;

    if (name === "cpf_cnpj_titular") {
      mascara = true;

      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue;

      if (Number(value.length) < 15) {
        maskedValue = cleanedValue
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1");
      } else {
        maskedValue = cleanedValue
          .replace(/(\d{2})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d{1,2})/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1");
      }

      setNewCliente({ ...newCliente, [name]: maskedValue });
    }

    if (name === "cpf_cnpj") {
      mascara = true;

      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue;

      if (newCliente.tipo_pessoa === "F") {
        maskedValue = cleanedValue
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1");
      } else {
        maskedValue = cleanedValue
          .replace(/(\d{2})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d{1,2})/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1");
      }

      setNewCliente({ ...newCliente, [name]: maskedValue });
    }

    if (name === "cep") {
      mascara = true;

      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue.replace(/(\d{5})(\d)/, "$1-$2");

      setNewCliente({ ...newCliente, [name]: maskedValue });

      if (maskedValue.length === 9) {
        fetchAddress(maskedValue);
      }
    }

    if (name === "telefone_fixo") {
      mascara = true;

      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");

      console.log(name, value, maskedValue);

      setNewCliente({ ...newCliente, [name]: maskedValue });
    }

    if (name === "celular") {
      mascara = true;

      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");

      setNewCliente({ ...newCliente, [name]: maskedValue });
    }

    if (mascara === false) {
      setNewCliente({ ...newCliente, [name]: value });
    }
  };

  const [newCliente, setNewCliente] = useState<Cliente>({
    id: 0,
    nome_fantasia: "",
    razao_social: "",
    cpf_cnpj: "",
    inscricao_estadual: "",
    cep: "",
    endereco: "",
    numero: "",
    bairro: "",
    cidade: "",
    uf: "",
    celular: "",
    email: "",
    tipo_pessoa: "F",
    tipo_cliente: 0,
    tipo_fornecedor: 0,
    tipo_transportador: 0,
  });

  const [habilitaClienteModal, setHabilitaClienteModal] = useState(false);
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNewCliente((prevState) => ({
      ...prevState,
      [name]: checked ? 1 : 0,
      antt: name === "tipo_transportador" && !checked ? "" : prevState.antt,
    }));
  };

  const [cpfCnpj, setCpfCnpj] = useState("CPF");
  const [razaoSocialNome, setRazaoSocialNome] = useState("Nome");
  const [nomeFantasiaApelido, setNomeFantasiaApelido] = useState("Apelido");

  const [venda, setVenda] = useState<VendaState>({
    idFazenda: 0,
    produto: "",
    subProduto: "",
    safra: "",
    volumeVenda: 0,
    dataCarregamentoDe: "",
    dataCarregamentoAte: "",
    dataPagamento: "",
    valorVenda: "",
    armazenamento: "",
    destino: "",
    lote: "",
    cliente: 0,
    talhoes: [],
  });

  const applyMask = (value: string, uf: string) => {
    let cleanedValue = value.replace(/\D/g, "");
    switch (uf) {
      case "AC":
        return cleanedValue.replace(
          /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,3})?(\d{1,2})?/,
          (_, p1, p2, p3, p4, p5) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `/${p4}`, p5 && `-${p5}`]
              .filter(Boolean)
              .join("")
        );
      case "AL":
        return cleanedValue.replace(/(\d{1,9})/, "$1");
      case "AM":
        return cleanedValue.replace(
          /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/,
          (_, p1, p2, p3, p4) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`]
              .filter(Boolean)
              .join("")
        );
      case "AP":
        return cleanedValue.replace(/(\d{1,9})/, "$1");
      case "BA":
        return cleanedValue.replace(/(\d{1,6})(\d{1,2})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "CE":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "DF":
        return cleanedValue.replace(
          /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/,
          (_, p1, p2, p3, p4, p5) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `/${p4}`, p5 && `-${p5}`]
              .filter(Boolean)
              .join("")
        );
      case "ES":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "GO":
        return cleanedValue.replace(
          /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/,
          (_, p1, p2, p3, p4) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`]
              .filter(Boolean)
              .join("")
        );
      case "MA":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "MG":
        return cleanedValue.replace(
          /(\d{1,7})?(\d{1,2})?(\d{1,2})?/,
          (_, p1, p2, p3) =>
            [p1, p2 && `.${p2}`, p3 && `-${p3}`].filter(Boolean).join("")
        );
      case "MS":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "MT":
        return cleanedValue.replace(/(\d{1,10})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "PA":
        return cleanedValue.replace(
          /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/,
          (_, p1, p2, p3, p4) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`]
              .filter(Boolean)
              .join("")
        );
      case "PB":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "PE":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "PI":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "PR":
        return cleanedValue.replace(
          /(\d{1,3})(\d{1,5})?(\d{1,2})?/,
          (_, p1, p2, p3) =>
            [p1, p2 && `.${p2}`, p3 && `-${p3}`].filter(Boolean).join("")
        );
      case "RJ":
        return cleanedValue.replace(
          /(\d{1,2})(\d{1,3})?(\d{1,2})?(\d{1})?/,
          (_, p1, p2, p3, p4) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`]
              .filter(Boolean)
              .join("")
        );
      case "RN":
        return cleanedValue.replace(
          /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?/,
          (_, p1, p2, p3, p4) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`]
              .filter(Boolean)
              .join("")
        );
      case "RO":
        return cleanedValue.replace(/(\d{1,13})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "RR":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "RS":
        return cleanedValue.replace(/(\d{1,3})(\d{1,7})?/, (_, p1, p2) =>
          [p1, p2 && `/${p2}`].filter(Boolean).join("")
        );
      case "SC":
        return cleanedValue.replace(
          /(\d{1,3})(\d{1,3})?(\d{1,3})?/,
          (_, p1, p2, p3) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`].filter(Boolean).join("")
        );
      case "SE":
        return cleanedValue.replace(/(\d{1,8})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      case "SP":
        return cleanedValue.replace(
          /(\d{1,3})(\d{1,3})?(\d{1,3})?(\d{1,3})?/,
          (_, p1, p2, p3, p4) =>
            [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `.${p4}`]
              .filter(Boolean)
              .join("")
        );
      case "TO":
        return cleanedValue.replace(/(\d{1,10})(\d{1})?/, (_, p1, p2) =>
          [p1, p2 && `-${p2}`].filter(Boolean).join("")
        );
      default:
        return value;
    }
  };

  const addCliente = () => {
    setNewCliente({
      id: 0,
      nome_fantasia: "",
      razao_social: "",
      cpf_cnpj: "",
      inscricao_estadual: "",
      cep: "",
      endereco: "",
      numero: "",
      bairro: "",
      cidade: "",
      uf: "",
      celular: "",
      email: "",
      tipo_pessoa: "F",
      tipo_cliente: 0,
      tipo_transportador: 0,
      tipo_fornecedor: 0,
    });
    setIsEditing(false);
    setShowCliente(true);
    console.log("asdsaada");
  };

  const handleCliente = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "tipo_pessoa") {
      newCliente.cpf_cnpj = "";

      if (value === "J") {
        setRazaoSocialNome("Razão Social");
        setNomeFantasiaApelido("Nome Fantasia");
        setCpfCnpj("CNPJ");
      } else {
        setRazaoSocialNome("Nome");
        setNomeFantasiaApelido("Apelido");
        setCpfCnpj("CPF");
      }
    }

    if (name === "cep") {
      if (value.length === 9) {
        fetchAddress(value);
      }
    }

    if (name === "uf") {
      newCliente.cidade = "";
      newCliente.cep = "";
      newCliente.inscricao_estadual = "";
      newCliente.endereco = "";
      newCliente.numero = "";
      newCliente.complemento = "";
      newCliente.bairro = "";

      setNewCliente((prevState) => ({
        ...prevState,
        inscricao_estadual: "",
        [name]: value,
      }));

      if (value === "") {
        setDisabledInscricao(true);
        setInscricaoPlaceholder("Selecione o Estado");
      } else {
        setDisabledInscricao(false);

        const placeholders: {
          [key: string]: { placeholder: string; maxlength: number };
        } = {
          AC: { placeholder: "__/____.____/___-__", maxlength: 18 },
          AL: { placeholder: "_________", maxlength: 9 },
          AM: { placeholder: "___.___.___-_", maxlength: 12 },
          AP: { placeholder: "_________", maxlength: 9 },
          BA: { placeholder: "______-__", maxlength: 9 },
          CE: { placeholder: "________-_", maxlength: 10 },
          DF: { placeholder: "__.___.___/___-__", maxlength: 15 },
          ES: { placeholder: "________-_", maxlength: 10 },
          GO: { placeholder: "__.____.____-_", maxlength: 12 },
          MA: { placeholder: "________-_", maxlength: 10 },
          MG: { placeholder: "_______.__-__", maxlength: 13 },
          MS: { placeholder: "________-_", maxlength: 10 },
          MT: { placeholder: "__________-_", maxlength: 12 },
          PA: { placeholder: "__.___.___-_", maxlength: 12 },
          PB: { placeholder: "________-_", maxlength: 10 },
          PE: { placeholder: "________-_", maxlength: 10 },
          PI: { placeholder: "________-_", maxlength: 10 },
          PR: { placeholder: "___._____-__", maxlength: 11 },
          RJ: { placeholder: "__.___.__-_", maxlength: 10 },
          RN: { placeholder: "__.___.___-_", maxlength: 12 },
          RO: { placeholder: "_____________-_", maxlength: 15 },
          RR: { placeholder: "________-_", maxlength: 10 },
          RS: { placeholder: "___/_______", maxlength: 11 },
          SC: { placeholder: "___.___.___", maxlength: 11 },
          SE: { placeholder: "________-_", maxlength: 10 },
          SP: { placeholder: "___.___.___.___", maxlength: 15 },
          TO: { placeholder: "__________-_", maxlength: 12 },
        };

        const { placeholder, maxlength } = placeholders[value] || {
          placeholder: "Selecione o Estado",
          maxlength: 100,
        };

        setInscricaoPlaceholder(placeholder);
        setUfComboSel(value);
        setNewCliente((prevState) => ({
          ...prevState,
          inscricao_estadual: "",
          maxlength,
        }));
      }
    } else {
      if (name == "cep" && value.length === 9) {
        applyMask(value, ufComboSel);
      }

      const maskedValue =
        name === "inscricao_estadual" ? applyMask(value, ufComboSel) : value;

      setNewCliente((prevState) => ({
        ...prevState,
        [name]: maskedValue,
      }));

      if (name === "nascimento") {
        let cleanedValue = value.replace(/\D/g, "");

        let maskedValue = cleanedValue
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d{1,4})/, "$1/$2");

        setNewCliente((prevState) => ({
          ...prevState,
          [name]: maskedValue,
        }));
      }
    }
  };

  function formatNumberWithThousandSeparator(value: number | string): string {
    return new Intl.NumberFormat("pt-BR").format(Number(value));
  }

  const [vendaModal, setVendaModal] = useState<VendaEditState>({
    id: 0,
    idFazenda: 0,
    produto: "",
    subProduto: "",
    safra: "",
    volumeVenda: 0,
    dataCarregamentoDe: "",
    dataCarregamentoAte: "",
    dataPagamento: "",
    valorVenda: "",
    armazenamento: "",
    destino: "",
    lote: "",
    cliente: 0,
    talhoes: [],
  });

  const [vendaDireta, setVendaDireta] = useState<VendaDiretaState>({
    idFazenda: 0,
    produto: "",
    subProduto: "",
    safra: "",
    volumeVenda: 0,
    dataCarregamentoDe: "",
    dataCarregamentoAte: "",
    dataPagamento: "",
    valorVenda: "",
    armazenamento: "",
    destino: "",
    lote: "",
    cliente: 0,
    talhoes: [],
  });

  const [tabelaTalhoes, setTabelaTalhoes] = useState<Talhao[]>([]);
  const [tabelaTalhoesModal, setTabelaTalhoesModal] = useState<Talhao[]>([]);

  const fetchClientes = async (search = "") => {
    try {
      const clientes = await getClientes(search);
      setClientes(clientes);
    } catch (error) {
      console.error("Erro ao buscar lista de clientes:", error);
    }
  };

  const handleSubmit = async () => {
    setAlerta("");

    if (!newCliente.razao_social) {
      setAlerta("O campo Razão Social/Nome é obrigatório.");
      return;
    }

    try {
      const clienteData = normalizeData(newCliente);
      let result: any;

      if (isEditing) {
        result = await updateCliente(clienteData);
      } else {
        result = await createCliente(clienteData);
      }

      if (result.status === "error") {
        setAlerta(result.messages[0]);
      } else {
        fetchClientes("");
        handleClose();
        setAlerta(
          isEditing
            ? "Cliente atualizado com sucesso."
            : "Cliente cadastrado com sucesso."
        );
        setShowCliente(false);
        clientesLista();
      }
    } catch (error) {
      setAlerta("Não foi possível realizar esta operação.");
    }
  };

  const [tabelaTalhoesVendaDireta, setTabelaTalhoesVendaDireta] = useState<
    Talhao[]
  >([]);

  const [talhoesOptions, setTalhoesOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [talhoesModalOptions, setTalhoesModalOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [talhoesVendaDiretaOptions, setTalhoesVendaDiretaOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [showProduto, setShowProduto] = useState(false);
  const [alerta, setAlerta] = useState("");
  const notify = () => toast.info(alerta);
  const [nomeUser, setNomeUser] = useState<string | undefined>(
    Cookies.get("NomeUserBrasao")
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showCliente, setShowCliente] = useState(false);
  const handleCloseCliente = () => setShowCliente(false);

  const bancoOptions = [
    { value: "743", label: "743 - Banco Semear S.A" },
    { value: "100", label: "100 - Planner Corretora De Valores S.A" },
    { value: "096", label: "096 - Banco B3 S.A" },
    { value: "747", label: "747 - Banco Rabobank Internacional Do Brasil S.A" },
    { value: "748", label: "748 - Sicredi S.A" },
    { value: "752", label: "752 - Bnp Paribas Brasil S.A" },
    { value: "091", label: "091 - Unicred Central Rs" },
    { value: "399", label: "399 - Kirton Bank" },
    { value: "108", label: "108 - Portocred S.A" },
    { value: "757", label: "757 - Banco Keb Hana Do Brasil S.A" },
    { value: "102", label: "102 - Xp Investimentos S.A" },
    { value: "348", label: "348 - Banco Xp S/A" },
    { value: "340", label: "340 - Super Pagamentos S/A (Superdital)" },
    { value: "364", label: "364 - Gerencianet Pagamentos Do Brasil" },
    { value: "084", label: "084 - Uniprime Norte Do Paraná" },
    { value: "180", label: "180 - Cm Capital Markets Cctvm Ltda" },
    { value: "066", label: "066 - Banco Morgan Stanley S.A" },
    { value: "015", label: "015 - Ubs Brasil Cctvm S.A" },
    { value: "143", label: "143 - Treviso Cc S.A" },
    { value: "062", label: "062 - Hipercard Bm S.A" },
    { value: "074", label: "074 - Bco. J.Safra S.A" },
    { value: "099", label: "099 - Uniprime Central Ccc Ltda" },
    { value: "025", label: "025 - Banco Alfa S.A." },
    { value: "075", label: "075 - Bco Abn Amro S.A" },
    { value: "040", label: "040 - Banco Cargill S.A" },
    { value: "190", label: "190 - Servicoop" },
    { value: "063", label: "063 - Banco Bradescard" },
    { value: "191", label: "191 - Nova Futura Ctvm Ltda" },
    { value: "064", label: "064 - Goldman Sachs Do Brasil Bm S.A" },
    { value: "097", label: "097 - Ccc Noroeste Brasileiro Ltda" },
    { value: "016", label: "016 - Ccm Desp Trâns Sc E Rs" },
    { value: "012", label: "012 - Banco Inbursa" },
    { value: "003", label: "003 - Banco Da Amazonia S.A" },
    { value: "060", label: "060 - Confidence Cc S.A" },
    { value: "037", label: "037 - Banco Do Estado Do Pará S.A" },
    { value: "159", label: "159 - Casa Credito S.A" },
    { value: "172", label: "172 - Albatross Ccv S.A" },
    { value: "085", label: "085 - Coop Central Ailos" },
    {
      value: "114",
      label: "114 - Central Cooperativa De Crédito No Estado Do Espírito Santo",
    },
    { value: "036", label: "036 - Banco Bbi S.A" },
    { value: "394", label: "394 - Banco Bradesco Financiamentos S.A" },
    { value: "004", label: "004 - Banco Do Nordeste Do Brasil S.A." },
    { value: "320", label: "320 - Banco Ccb Brasil S.A" },
    { value: "189", label: "189 - Hs Financeira" },
    { value: "105", label: "105 - Lecca Cfi S.A" },
    { value: "076", label: "076 - Banco Kdb Brasil S.A." },
    { value: "082", label: "082 - Banco Topázio S.A" },
    { value: "286", label: "286 - Ccr De Ouro" },
    { value: "093", label: "093 - Pólocred Scmepp Ltda" },
    { value: "273", label: "273 - Ccr De São Miguel Do Oeste" },
    { value: "157", label: "157 - Icap Do Brasil Ctvm Ltda" },
    { value: "183", label: "183 - Socred S.A" },
    { value: "014", label: "014 - Natixis Brasil S.A" },
    { value: "130", label: "130 - Caruana Scfi" },
    { value: "127", label: "127 - Codepe Cvc S.A" },
    { value: "079", label: "079 - Banco Original Do Agronegócio S.A" },
    { value: "081", label: "081 - Bbn Banco Brasileiro De Negocios S.A" },
    { value: "118", label: "118 - Standard Chartered Bi S.A" },
    { value: "133", label: "133 - Cresol Confederação" },
    { value: "121", label: "121 - Banco Agibank S.A" },
    { value: "083", label: "083 - Banco Da China Brasil S.A" },
    { value: "138", label: "138 - Get Money Cc Ltda" },
    { value: "024", label: "024 - Bco Bandepe S.A" },
    { value: "095", label: "095 - Banco Confidence De Câmbio S.A" },
    { value: "094", label: "094 - Banco Finaxis" },
    { value: "276", label: "276 - Senff S.A" },
    { value: "137", label: "137 - Multimoney Cc Ltda" },
    { value: "092", label: "092 - Brk S.A" },
    { value: "047", label: "047 - Banco Do Estado De Sergipe S.A" },
    { value: "144", label: "144 - Bexs Banco De Cambio S.A." },
    { value: "126", label: "126 - Br Partners Bi" },
    { value: "301", label: "301 - Bpp Instituição De Pagamentos S.A" },
    { value: "173", label: "173 - Brl Trust Dtvm Sa" },
    { value: "119", label: "119 - Banco Western Union" },
    { value: "254", label: "254 - Parana Banco S.A" },
    { value: "268", label: "268 - Barigui Ch" },
    { value: "107", label: "107 - Banco Bocom Bbm S.A" },
    { value: "412", label: "412 - Banco Capital S.A" },
    { value: "124", label: "124 - Banco Woori Bank Do Brasil S.A" },
    { value: "149", label: "149 - Facta S.A. Cfi" },
    { value: "197", label: "197 - Stone Pagamentos S.A" },
    { value: "142", label: "142 - Broker Brasil Cc Ltda" },
    { value: "184", label: "184 - Banco Itaú Bba S.A" },
    { value: "634", label: "634 - Banco Triangulo S.A (Banco Triângulo)" },
    { value: "545", label: "545 - Senso Ctvm S.A" },
    { value: "132", label: "132 - Icbc Do Brasil Bm S.A" },
    { value: "298", label: "298 - Vips Cc Ltda" },
    { value: "129", label: "129 - Ubs Brasil Bi S.A" },
    { value: "128", label: "128 - Ms Bank S.A Banco De Câmbio" },
  ];

  const [loteId, setLoteId] = useState(null);

  const [showLote, setShowLote] = useState(false);
  const handleCloseLote = () => setShowLote(false);

  const cancelarLote = (id: any) => {
    setLoteId(id);
    setShow(true);
  };

  const confirmarCancelarLote = () => {
    setAlerta("");

    if (loteId !== null) {
      const cancelaVenda = deleteVenda(loteId);
      cancelaVenda
        .then(() => {
          setListaVendas((prevVendas) =>
            prevVendas.filter((venda) => venda.id !== loteId)
          );
          setShow(false);

          setAlerta(`Lote ${loteId} cancelado com sucesso!`);
        })
        .catch((error) => {
          console.error("Erro ao cancelar lote:", error);
        });
    }
  };

  const formatarValor = (valor: any) => {
    if (!valor) return ""; // Retorna vazio se o valor for nulo ou indefinido

    const numero = parseFloat(valor); // Converte para número
    if (isNaN(numero)) return ""; // Retorna vazio se não for um número válido

    return new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numero);
  };

  const handleOpenLote = async (id: any, tipo: string) => {
    setLoteId(id);
    setShowLote(true);

    if (tipo === "desfazer") {
      setEfetivaModalVenda(false);
      setEditaModalVenda(false);
      setHabilitaClienteModal(true);
      setDesfazerModalVenda(true);
    }

    if (tipo === "editar") {
      setEfetivaModalVenda(false);
      setEditaModalVenda(true);
      setHabilitaClienteModal(false);
      setDesfazerModalVenda(false);
    }

    if (tipo === "editar venda direta") {
      setEfetivaModalVenda(true);
      setEditaModalVenda(true);
      setHabilitaClienteModal(true);
      setDesfazerModalVenda(false);
    }

    if (tipo === "efetivar") {
      setEditaModalVenda(false);
      setEfetivaModalVenda(true);
      setHabilitaClienteModal(true);
      setDesfazerModalVenda(false);
    }

    try {
      const resultVenda = await getVendaById(id);

      console.log(resultVenda);

      setVendaModal((prev) => ({
        ...prev,
        idFazenda: resultVenda.idFazenda || 0,
        produto: resultVenda.produto || "",
        subProduto: resultVenda.sub_produto || "",
        safra: resultVenda.safra || "",
        volumeVenda: parseFloat(resultVenda.volume_venda) || 0,
        dataCarregamentoDe: resultVenda.data_carregamento_de || "",
        dataCarregamentoAte: resultVenda.data_carregamento_ate || "",
        dataPagamento: resultVenda.data_pagamento || "",
        valorVenda: formatarValor(resultVenda.valor_venda_bruto) || "",
        armazenamento: resultVenda.armazenamento || "",
        destino: resultVenda.destino || "",
        lote: resultVenda.lote || "",
        cliente: resultVenda.idCliente || 0,
      }));

      if (resultVenda.idFazenda) {
        const fetchTalhoes = async () => {
          try {
            const talhoes = await getFilteredTalhoes({
              idFazenda: resultVenda.idFazenda,
            });

            const formattedTalhoes = talhoes.map((talhao: any) => {
              const produtividade = parseFloat(talhao.produtividade) || 0;
              const volumeDisponivel =
                talhao.unidade === "Ton"
                  ? produtividade * 1000
                  : talhao.unidade === "Saco"
                    ? produtividade * 60
                    : produtividade;

              return {
                value: String(talhao.id),
                label: `${talhao.talhao_nome} - Volume disponível: ${formatNumberWithThousandSeparator(volumeDisponivel - talhao.total_quantidade_kilograma)} (kg)`,
                nome: `${talhao.talhao_nome}`,
                volumeDisponivel: formatNumberWithThousandSeparator(
                  volumeDisponivel - talhao.total_quantidade_kilograma
                ),
                volumeTipo: talhao.unidade,
                data_colheita: talhao.data_colheita,
              };
            });

            setTalhoesModalOptions(formattedTalhoes);

            if (resultVenda.idProducao) {
              const selectedTalhoes = JSON.parse(resultVenda.idProducao).map(
                (item: any) =>
                  formattedTalhoes.find(
                    (talhao: any) => talhao.value === String(item.idProducao)
                  )
              );

              setVendaModal((prev) => ({
                ...prev,
                talhoes: selectedTalhoes,
              }));

              const parsedProducao = JSON.parse(resultVenda.idProducao);

              const tabelaTalhoes = selectedTalhoes.map((talhao: any) => {
                const producaoMatch = parsedProducao.find(
                  (producao: any) =>
                    producao.idProducao === String(talhao.value)
                );

                return {
                  idProducao: talhao.value,
                  nome: talhao.nome,
                  volumeDisponivel: formatNumberWithThousandSeparator(
                    talhao.volumeDisponivel
                  ),
                  volumeTipo: talhao.volumeTipo,
                  data_colheita: talhao.data_colheita,
                  volumeUtilizado: producaoMatch?.volumeUtilizado || 0,
                };
              });

              setTabelaTalhoesModal(tabelaTalhoes);
            }
          } catch (error) {
            console.error("Erro ao buscar talhões:", error);
          }
        };
        await fetchTalhoes();
      }
    } catch (error) {
      console.error("Erro ao obter dados da venda:", error);
    }
  };

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  const fetchFazendas = async () => {
    try {
      const response = await getFazendas();
      setFazendas(response);
    } catch (error) {
      console.error("Erro ao buscar fazendas:", error);
    }
  };

  const handleCloseProduto = () => setShowProduto(false);

  const modalProduto = () => {
    setShowProduto(true);
  };

  const fetchProdutos = async () => {
    try {
      const response = await getProdutos();
      setProdutos(response);
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    }
  };

  const fetchSubprodutos = async () => {
    try {
      const response = await getSubprodutos();
      setSubprodutos(response);
    } catch (error) {
      console.error("Erro ao buscar subprodutos:", error);
    }
  };

  const style = document.createElement("style");
  style.innerHTML = `
      .modal-dialog{
        min-width: 90%;
      }
  
    .modalProduto .modal-dialog{
        min-width: 30%;
      }

    .modalCliente .modal-dialog{
        min-width: 400px;
      }  
    `;
  document.head.appendChild(style);

  const handleVolumeChange = (index: number, value: number) => {
    setTabelaTalhoes((prev) => {
      const updated = [...prev];
      updated[index].volumeUtilizado = value;
      return updated;
    });

    const totalVolume = tabelaTalhoes.reduce((sum, talhao, idx) => {
      return sum + (idx === index ? value : talhao.volumeUtilizado || 0);
    }, 0);

    setVenda((prev) => ({ ...prev, volumeVenda: totalVolume }));
  };

  const handleVolumeModalChange = (index: number, value: number) => {
    setTabelaTalhoesModal((prev) => {
      const updated = [...prev];
      updated[index].volumeUtilizado = value;
      return updated;
    });

    const totalVolume = tabelaTalhoesModal.reduce((sum, talhao, idx) => {
      return sum + (idx === index ? value : talhao.volumeUtilizado || 0);
    }, 0);

    setVendaModal((prev) => ({ ...prev, volumeVenda: totalVolume }));
  };

  const handleVolumeVendaDiretaChange = (index: number, value: number) => {
    setTabelaTalhoesVendaDireta((prev) => {
      const updated = [...prev];
      updated[index].volumeUtilizado = value;
      return updated;
    });

    const totalVolume = tabelaTalhoesVendaDireta.reduce((sum, talhao, idx) => {
      return sum + (idx === index ? value : talhao.volumeUtilizado || 0);
    }, 0);

    setVendaDireta((prev) => ({ ...prev, volumeVenda: totalVolume }));
  };

  const handleVendaModalChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setVendaModal((prev) => ({ ...prev, [name]: value }));

    setAlerta("");

    const updatedVenda = {
      ...vendaModal,
      [name]: value,
    };

    if (
      name === "dataCarregamentoDe" ||
      name === "dataCarregamentoAte" ||
      name === "dataPagamento"
    ) {
      const dataCarregamentoDe = new Date(venda.dataCarregamentoDe);
      const dataCarregamentoAte = new Date(venda.dataCarregamentoAte);
      const dataPagamento = new Date(venda.dataPagamento);

      if (
        name === "dataCarregamentoAte" &&
        dataCarregamentoAte <
          new Date(dataCarregamentoDe.getTime() + 7 * 24 * 60 * 60 * 1000)
      ) {
        setAlerta(
          "A data de carregamento Até deve ser pelo menos 7 dias maior do que a data de carregamento De."
        );
        return;
      }

      if (name === "dataPagamento" && dataPagamento < dataCarregamentoAte) {
        setAlerta(
          "A data de pagamento não pode ser menor do que a data de carregamento Até."
        );
        return;
      }
    }

    if (
      name === "idFazenda" ||
      name === "produto" ||
      name === "subProduto" ||
      name === "safra"
    ) {
      const fetchTalhoes = async () => {
        try {
          const talhoes = await getFilteredTalhoes({
            idFazenda: updatedVenda.idFazenda,
            produto: updatedVenda.produto,
            subProduto: updatedVenda.subProduto,
            safra: updatedVenda.safra,
          });

          const formattedTalhoes = talhoes.map((talhao: any) => {
            const produtividade = parseFloat(talhao.produtividade) || 0;
            const volumeDisponivel =
              talhao.unidade === "Ton"
                ? produtividade * 1000
                : talhao.unidade === "Saco"
                  ? produtividade * 60
                  : produtividade;

            return {
              value: String(talhao.id),
              label: `${talhao.talhao_nome} - Volume disponível: ${formatNumberWithThousandSeparator(volumeDisponivel - talhao.total_quantidade_kilograma)} (kg)`,
              nome: `${talhao.talhao_nome}`,
              volumeDisponivel: formatNumberWithThousandSeparator(
                volumeDisponivel - talhao.total_quantidade_kilograma
              ),
              data_colheita: talhao.data_colheita,
            };
          });
          setTalhoesModalOptions(formattedTalhoes);
        } catch (error) {
          console.error("Erro ao buscar talhões:", error);
        }
      };
      fetchTalhoes();
    }
  };

  const normalizeDate = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const handleVendaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setVenda((prev) => ({ ...prev, [name]: value }));

    setAlerta("");

    const updatedVenda = {
      ...venda,
      [name]: value,
    };

    if (
      name === "dataCarregamentoDe" ||
      name === "dataCarregamentoAte" ||
      name === "dataPagamento"
    ) {
      const dataCarregamentoDe = venda.dataCarregamentoDe
        ? normalizeDate(new Date(venda.dataCarregamentoDe))
        : null;

      const dataCarregamentoAte = venda.dataCarregamentoAte
        ? normalizeDate(new Date(venda.dataCarregamentoAte))
        : null;

      const dataPagamento = venda.dataPagamento
        ? normalizeDate(new Date(venda.dataPagamento))
        : null;
      const dataAtual = normalizeDate(new Date());

      const dataAtualMenosUm = new Date(dataAtual);
      dataAtualMenosUm.setDate(dataAtual.getDate() - 1);

      const inputRefs: { [key: string]: HTMLInputElement | null } = {
        dataCarregamentoDe: document.querySelector(
          'input[name="dataCarregamentoDe"]'
        ),
        dataCarregamentoAte: document.querySelector(
          'input[name="dataCarregamentoAte"]'
        ),
        dataPagamento: document.querySelector('input[name="dataPagamento"]'),
      };

      if (dataCarregamentoDe) {
        if (
          name === "dataCarregamentoDe" &&
          dataCarregamentoDe &&
          dataCarregamentoDe < dataAtualMenosUm
        ) {
          setAlerta(
            "A data de carregamento De não pode ser menor do que a data atual."
          );
          setVenda((prev) => ({ ...prev, dataCarregamentoDe: "" }));
          inputRefs.dataCarregamentoDe?.focus();
          return;
        }
      }

      if (dataCarregamentoAte && dataCarregamentoDe) {
        if (
          name === "dataCarregamentoAte" &&
          dataCarregamentoAte <
            new Date(dataCarregamentoDe.getTime() + 7 * 24 * 60 * 60 * 1000)
        ) {
          setAlerta(
            "A data de carregamento Até deve ser pelo menos 7 dias maior do que a data de carregamento De."
          );
          setVenda((prev) => ({ ...prev, dataCarregamentoAte: "" }));
          inputRefs.dataCarregamentoAte?.focus();
          return;
        }
      }

      if (dataPagamento && dataCarregamentoAte) {
        if (name === "dataPagamento" && dataPagamento < dataCarregamentoAte) {
          setAlerta(
            "A data de pagamento não pode ser menor do que a data de carregamento Até."
          );
          setVenda((prev) => ({ ...prev, dataPagamento: "" }));
          inputRefs.dataPagamento?.focus();
          return;
        }
      }
    }

    if (
      name === "idFazenda" ||
      name === "produto" ||
      name === "subProduto" ||
      name === "safra"
    ) {
      const fetchTalhoes = async () => {
        try {
          const talhoes = await getFilteredTalhoes({
            idFazenda: updatedVenda.idFazenda,
            produto: updatedVenda.produto,
            subProduto: updatedVenda.subProduto,
            safra: updatedVenda.safra,
          });
          const formattedTalhoes = talhoes.map((talhao: any) => {
            const produtividade = parseFloat(talhao.produtividade) || 0;
            const volumeDisponivel =
              talhao.unidade === "Ton"
                ? produtividade * 1000
                : talhao.unidade === "Saco"
                  ? produtividade * 60
                  : produtividade;

            return {
              value: String(talhao.id),
              label: `${talhao.talhao_nome} - Volume disponível: ${formatNumberWithThousandSeparator(volumeDisponivel - talhao.total_quantidade_kilograma)} (kg)`,
              nome: `${talhao.talhao_nome}`,
              volumeDisponivel: formatNumberWithThousandSeparator(
                volumeDisponivel - talhao.total_quantidade_kilograma
              ),
              data_colheita: talhao.data_colheita,
            };
          });
          setTalhoesOptions(formattedTalhoes);
        } catch (error) {
          console.error("Erro ao buscar talhões:", error);
        }
      };
      fetchTalhoes();
    }
  };

  const handleVendaDiretaChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setVendaDireta((prev) => ({ ...prev, [name]: value }));

    setAlerta("");

    const updatedVenda = {
      ...vendaDireta,
      [name]: value,
    };

    if (
      name === "dataCarregamentoDeVendaDireta" ||
      name === "dataCarregamentoAteVendaDireta" ||
      name === "dataPagamento"
    ) {
      const dataCarregamentoDe = new Date(vendaDireta.dataCarregamentoDe);
      const dataCarregamentoAte = new Date(vendaDireta.dataCarregamentoAte);
      const dataPagamento = new Date(vendaDireta.dataPagamento);

      const inputRefs: { [key: string]: HTMLInputElement | null } = {
        dataCarregamentoDe: document.querySelector(
          'input[name="dataCarregamentoDeVendaDireta"]'
        ),
        dataCarregamentoAte: document.querySelector(
          'input[name="dataCarregamentoAteVendaDireta"]'
        ),
        dataPagamento: document.querySelector('input[name="dataPagamento"]'),
      };

      if (name === "dataCarregamentoAteVendaDireta") {
        if (
          dataCarregamentoDe &&
          dataCarregamentoAte &&
          dataCarregamentoAte < dataCarregamentoDe
        ) {
          setAlerta(
            "A data de carregamento Até não pode ser inferior à data de carregamento De."
          );
          setVendaDireta((prev) => ({ ...prev, dataCarregamentoAte: "" }));
          inputRefs.dataCarregamentoAte?.focus();
          return;
        }
      }

      if (name === "dataCarregamentoDeVendaDireta") {
        if (
          dataCarregamentoDe &&
          dataCarregamentoAte &&
          dataCarregamentoDe > dataCarregamentoAte
        ) {
          setAlerta(
            "A data de carregamento De não pode ser superior à data de carregamento Até."
          );
          setVendaDireta((prev) => ({ ...prev, dataCarregamentoDe: "" }));
          inputRefs.dataCarregamentoDe?.focus();
          return;
        }

        if (
          dataCarregamentoDe &&
          dataColheitaMaisFutura &&
          new Date(dataCarregamentoDe) < new Date(dataColheitaMaisFutura)
        ) {
          setAlerta(
            "A data de carregamento De não pode ser inferior à data de colheita mais futura."
          );
          setVendaDireta((prev) => ({ ...prev, dataCarregamentoDe: "" }));
          inputRefs.dataCarregamentoDe?.focus();
          return;
        }
      }
    }

    if (
      name === "idFazenda" ||
      name === "produto" ||
      name === "subProduto" ||
      name === "safra"
    ) {
      const fetchTalhoes = async () => {
        try {
          const talhoes = await getFilteredTalhoes({
            idFazenda: updatedVenda.idFazenda,
            produto: updatedVenda.produto,
            subProduto: updatedVenda.subProduto,
            safra: updatedVenda.safra,
          });

          const formattedTalhoes = talhoes.map((talhao: any) => {
            const produtividade = parseFloat(talhao.produtividade) || 0;
            const volumeDisponivel =
              talhao.unidade === "Ton"
                ? produtividade * 1000
                : talhao.unidade === "Saco"
                  ? produtividade * 60
                  : produtividade;

            return {
              value: String(talhao.id),
              label: `${talhao.talhao_nome} - Volume disponível: ${formatNumberWithThousandSeparator(volumeDisponivel - talhao.total_quantidade_kilograma)} (kg)`,
              nome: `${talhao.talhao_nome}`,
              volumeDisponivel: formatNumberWithThousandSeparator(
                volumeDisponivel - talhao.total_quantidade_kilograma
              ),
              data_colheita: talhao.data_colheita,
            };
          });
          setTalhoesVendaDiretaOptions(formattedTalhoes);
        } catch (error) {
          console.error("Erro ao buscar talhões:", error);
        }
      };
      fetchTalhoes();
    }
  };

  const handleTalhoesChange = (selected: any) => {
    setVenda((prev) => ({ ...prev, talhoes: selected }));

    const novosTalhoes = selected.map((talhao: any) => {
      const volumeConvertido =
        talhao.volumeTipo === "Ton"
          ? talhao.volumeDisponivel * 1000
          : talhao.volumeTipo === "Saco"
            ? talhao.volumeDisponivel * 60
            : talhao.volumeDisponivel;

      const talhaoExistente = tabelaTalhoes.find(
        (t: any) => t.idProducao === talhao.value
      );

      return {
        idProducao: talhao.value,
        nome: talhao.nome,
        volumeDisponivel: volumeConvertido,
        volumeTipo: talhao.volumeTipo,
        volumeUtilizado: talhaoExistente ? talhaoExistente.volumeUtilizado : 0,
        data_colheita: talhao.data_colheita,
      };
    });

    setTabelaTalhoes(novosTalhoes);

    let volumeUtilizadoAtualizado = 0;

    novosTalhoes.forEach((talhao: any, index: any) => {
      if (talhao.volumeUtilizado > 0) {
        console.log(index, talhao.volumeUtilizado);
        handleVolumeChange(index, talhao.volumeUtilizado);

        volumeUtilizadoAtualizado += talhao.volumeUtilizado;
      }
    });

    setVenda((prev) => ({ ...prev, volumeVenda: volumeUtilizadoAtualizado }));
  };

  const handleTalhoesVendaDiretaChange = (selected: any) => {
    setVendaDireta((prev) => ({ ...prev, talhoes: selected }));

    const novosTalhoes = selected.map((talhao: any) => {
      const volumeConvertido =
        talhao.volumeTipo === "Ton"
          ? talhao.volumeDisponivel * 1000
          : talhao.volumeTipo === "Saco"
            ? talhao.volumeDisponivel * 60
            : talhao.volumeDisponivel;

      const talhaoExistente = tabelaTalhoesVendaDireta.find(
        (t: any) => t.idProducao === talhao.value
      );

      return {
        idProducao: talhao.value,
        nome: talhao.nome,
        volumeDisponivel: volumeConvertido,
        volumeTipo: talhao.volumeTipo,
        volumeUtilizado: talhaoExistente ? talhaoExistente.volumeUtilizado : 0,
        data_colheita: talhao.data_colheita,
      };
    });

    setTabelaTalhoesVendaDireta(novosTalhoes);

    const novaDataColheitaMaisFutura = novosTalhoes
      .filter((talhao: any) => talhao.data_colheita)
      .map((talhao: any) => talhao.data_colheita)
      .reduce((maisFutura: string | null, dataAtual: string) => {
        const dataColheita = new Date(dataAtual);
        if (!maisFutura || dataColheita > new Date(maisFutura)) {
          return dataAtual;
        }
        return maisFutura;
      }, null);

    setDataColheitaMaisFutura(novaDataColheitaMaisFutura);

    let volumeUtilizadoAtualizado = 0;

    novosTalhoes.forEach((talhao: any, index: any) => {
      if (talhao.volumeUtilizado > 0) {
        handleVolumeVendaDiretaChange(index, talhao.volumeUtilizado);
        volumeUtilizadoAtualizado += talhao.volumeUtilizado;
      }
    });

    setVendaDireta((prev) => ({
      ...prev,
      volumeVenda: volumeUtilizadoAtualizado,
    }));
  };

  const handleTalhoesModalChange = (selected: any) => {
    setVendaModal((prev) => ({ ...prev, talhoes: selected }));

    const novosTalhoes = selected.map((talhao: any) => {
      const volumeConvertido =
        talhao.volumeTipo === "Ton"
          ? talhao.volumeDisponivel * 1000
          : talhao.volumeTipo === "Saco"
            ? talhao.volumeDisponivel * 60
            : talhao.volumeDisponivel;

      const talhaoExistente = tabelaTalhoesModal.find(
        (t: any) => t.idProducao === talhao.value
      );

      return {
        idProducao: talhao.value,
        nome: talhao.nome,
        volumeDisponivel: volumeConvertido,
        volumeTipo: talhao.volumeTipo,
        volumeUtilizado: talhaoExistente ? talhaoExistente.volumeUtilizado : 0,
        data_colheita: talhao.data_colheita,
      };
    });

    setTabelaTalhoesModal(novosTalhoes);

    let volumeUtilizadoAtualizado = 0;

    novosTalhoes.forEach((talhao: any, index: any) => {
      if (talhao.volumeUtilizado > 0) {
        handleVolumeModalChange(index, talhao.volumeUtilizado);
        volumeUtilizadoAtualizado += talhao.volumeUtilizado;
      }
    });

    setVendaModal((prev) => ({
      ...prev,
      volumeVenda: volumeUtilizadoAtualizado,
    }));
  };

  function formatDateToDDMMYYYY(date: string): string {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  const parseInputToNumber = (value: string): number => {
    return Number(value.replace(/\./g, "").replace(",", "."));
  };

  const handleSubmitVendaDireta = async () => {
    setAlerta("");

    const hasInvalidVolume = tabelaTalhoesVendaDireta.some(
      (talhao) => !talhao.volumeUtilizado || talhao.volumeUtilizado <= 0
    );

    if (hasInvalidVolume) {
      setAlerta(
        "O volume a ser utilizado não pode ser zero ou vazio em nenhum talhão."
      );
      return;
    }

    const dataCarregamentoDe = new Date(vendaDireta.dataCarregamentoDe);
    const dataCarregamentoAte = new Date(vendaDireta.dataCarregamentoAte);

    if (
      dataCarregamentoDe &&
      dataCarregamentoAte &&
      dataCarregamentoAte < dataCarregamentoDe
    ) {
      setAlerta(
        "A data de carregamento Até não pode ser inferior à data de carregamento De."
      );
      return;
    }

    const vendaPayload = {
      ...vendaDireta,
      talhoes: tabelaTalhoesVendaDireta.map((talhao) => ({
        idProducao: talhao.idProducao,
        volumeUtilizado: talhao.volumeUtilizado,
      })),
    };

    const response = await createVendaDireta(vendaPayload);

    if (response) {
      if (response.status === "error") {
        const formattedMessage = response.messages.join("\n");
        setAlerta(formattedMessage);
      }

      if (response.status === "success") {
        setAlerta("Venda direta cadastrada com sucesso!");
        setVendaDireta({
          idFazenda: 0,
          produto: "",
          subProduto: "",
          safra: "",
          volumeVenda: 0,
          dataCarregamentoDe: "",
          dataCarregamentoAte: "",
          dataPagamento: "",
          valorVenda: "",
          armazenamento: "",
          destino: "",
          lote: "",
          cliente: 0,
          talhoes: [],
        });
        vendas();
      }
    }
  };

  const handleSubmitVenda = async () => {
    setAlerta("");

    if (!venda.talhoes || venda.talhoes.length === 0) {
      setAlerta("É necessário selecionar pelo menos um talhão.");
      return;
    }

    const dataCarregamentoDe = new Date(venda.dataCarregamentoDe);
    const dataCarregamentoAte = new Date(venda.dataCarregamentoAte);
    const dataPagamento = new Date(venda.dataPagamento);

    if (
      dataCarregamentoAte <
      new Date(dataCarregamentoDe.getTime() + 7 * 24 * 60 * 60 * 1000)
    ) {
      setAlerta(
        "A data de carregamento Até deve ser pelo menos 7 dias maior do que a data de carregamento De."
      );
      return;
    }

    if (dataPagamento < dataCarregamentoAte) {
      setAlerta(
        "A data de pagamento não pode ser menor do que a data de carregamento Até."
      );
      return;
    }

    const vendaPayload = {
      ...venda,
      talhoes: tabelaTalhoes.map((talhao) => ({
        idProducao: talhao.idProducao,
        volumeUtilizado: talhao.volumeUtilizado,
      })),
    };

    const response = await createVenda(vendaPayload);

    if (response) {
      if (response.status === "error") {
        const formattedMessage = response.messages.join("\n");
        setAlerta(formattedMessage);
      }

      if (response.status === "success") {
        setAlerta("Venda cadastrada com sucesso!");
        setVenda({
          idFazenda: 0,
          produto: "",
          subProduto: "",
          safra: "",
          volumeVenda: 0,
          dataCarregamentoDe: "",
          dataCarregamentoAte: "",
          dataPagamento: "",
          valorVenda: "",
          armazenamento: "",
          destino: "",
          lote: "",
          cliente: 0,
          talhoes: [],
        });

        vendas();
        setActiveTab("lotesNegociacao");
      }
    }
  };

  const handleSubmitVendaModal = async (acao: string) => {
    setAlerta("");

    if (!vendaModal.talhoes || vendaModal.talhoes.length === 0) {
      setAlerta("É necessário selecionar pelo menos um talhão.");
      return;
    }

    const dataCarregamentoDe = new Date(vendaModal.dataCarregamentoDe);
    const dataCarregamentoAte = new Date(vendaModal.dataCarregamentoAte);
    const dataPagamento = new Date(vendaModal.dataPagamento);

    if (
      dataCarregamentoAte <
      new Date(dataCarregamentoDe.getTime() + 7 * 24 * 60 * 60 * 1000)
    ) {
      setAlerta(
        "A data de carregamento Até deve ser pelo menos 7 dias maior do que a data de carregamento De."
      );
      return;
    }

    if (dataPagamento < dataCarregamentoAte) {
      setAlerta(
        "A data de pagamento não pode ser menor do que a data de carregamento Até."
      );
      return;
    }

    let realizada = null;

    if (acao == "efetiva") {
      realizada = 1;
    }

    if (acao == "desfazer") {
      realizada = null;
    }

    const vendaPayload = {
      ...vendaModal,
      id: loteId,
      realizada: realizada,
      talhoes: tabelaTalhoesModal.map((talhao) => ({
        idProducao: talhao.idProducao,
        volumeUtilizado: talhao.volumeUtilizado,
      })),
    };

    const response = await updateVenda(vendaPayload);

    if (response) {
      if (response.status === "error") {
        const formattedMessage = response.messages.join("\n");
        setAlerta(formattedMessage);
      }

      if (response.status === "success") {
        setAlerta(`Lote #${loteId} atualizado com sucesso!`);
        handleCloseLote();
        vendas();
      }
    }
  };

  function formatCurrencyBRL(value: number | string): string {
    return new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  }

  const vendas = async () => {
    setListaVendas(await getVendasByUser());
  };

  const clientesLista = async () => {
    setListaClientes(await getClientesByUser());
  };

  const handleBanco = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setNewCliente({ ...newCliente, ["banco"]: selectedOption?.value });
  };

  const isTransportadorSelected = newCliente.tipo_transportador;

  useEffect(() => {
    fetchFazendas();
    fetchProdutos();
    fetchSubprodutos();
    vendas();
    clientesLista();
  }, []);

  return (
    <>
      <HeaderComponent />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Cadastro de Venda</h5>
                </div>
                <div className="card-body">
                  <Tabs
                    defaultActiveKey="novaVenda"
                    className="mb-3"
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key ?? "novaVenda")}
                  >
                    <Tab eventKey="novaVenda" title="Novo lote de venda">
                      <Row>
                        <Col lg={12}>
                          <h5>
                            Vendedor: <b>{nomeUser}</b>
                          </h5>
                          <hr />
                        </Col>
                        <Col lg={4}>
                          <div className="form-group">
                            <label>Fazenda:</label>
                            <select
                              className="form-control"
                              name="idFazenda"
                              value={venda.idFazenda}
                              onChange={handleVendaChange}
                            >
                              <option value="">Selecione</option>
                              {fazendas.map((fazenda) => (
                                <option key={fazenda.id} value={fazenda.id}>
                                  {fazenda.nome}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="form-group">
                            <label>Produto:</label>
                            <div className="d-flex">
                              <select
                                className="form-control"
                                name="produto"
                                value={venda.produto}
                                onChange={handleVendaChange}
                              >
                                <option value="">Selecione</option>
                                {produtos.map((produto) => (
                                  <option
                                    key={produto.id}
                                    value={produto.descricao}
                                  >
                                    {produto.descricao}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="form-group">
                            <label>Sub Produto: </label>
                            <div className="d-flex">
                              <select
                                className="form-control"
                                name="subProduto"
                                value={venda.subProduto}
                                onChange={handleVendaChange}
                              >
                                <option value="">Selecione</option>
                                {subprodutos.map((subproduto) => (
                                  <option
                                    key={subproduto.id}
                                    value={subproduto.descricao}
                                  >
                                    {subproduto.descricao}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col lg={2}>
                          <div className="form-group">
                            <label>Safra: </label>
                            <select
                              className="form-control"
                              name="safra"
                              value={venda.safra}
                              onChange={handleVendaChange}
                            >
                              <option value="">Selecione a Safra</option>
                              <option value="2023/2024">2023/2024</option>
                              <option value="2024/2025">2024/2025</option>
                              <option value="2025/2026">2025/2026</option>
                            </select>
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="form-group">
                            <label>Talhões:</label>
                            <Select
                              options={talhoesOptions}
                              isMulti
                              placeholder="Selecione"
                              value={venda.talhoes}
                              onChange={handleTalhoesChange}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <table className="table table-striped mb-4 mt-0">
                            <thead>
                              <tr>
                                <th>Nome do talhão</th>
                                <th>Data da colheita</th>
                                <th>Volume disponível (kg)</th>
                                <th>Volume a ser utilizado (kg)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tabelaTalhoes.map((talhao, index) => (
                                <tr key={index}>
                                  <td>{talhao.nome}</td>
                                  <td>
                                    {formatDateToDDMMYYYY(talhao.data_colheita)}
                                  </td>
                                  <td>{talhao.volumeDisponivel} kg</td>
                                  <td>
                                    <InputDecimal0Component
                                      max={talhao.volumeDisponivel}
                                      value={formatNumberWithThousandSeparator(
                                        talhao.volumeUtilizado || 0
                                      )}
                                      onChange={(e) => {
                                        const inputValue = Number(
                                          e.target.value
                                            .replace(/\./g, "")
                                            .replace(",", ".")
                                        );

                                        function parseToNumber(
                                          value: string
                                        ): number {
                                          return Number(
                                            value
                                              .replace(/\./g, "")
                                              .replace(",", ".")
                                          );
                                        }

                                        console.log(
                                          Number(inputValue),
                                          parseToNumber(
                                            String(talhao.volumeDisponivel)
                                          )
                                        );

                                        setAlerta("");

                                        if (
                                          Number(inputValue) <=
                                          parseToNumber(
                                            String(talhao.volumeDisponivel)
                                          )
                                        ) {
                                          handleVolumeChange(index, inputValue);
                                        } else {
                                          handleVolumeChange(index, 0);
                                          setAlerta(
                                            "O volume a ser utilizado não pode ser maior que o volume disponível."
                                          );
                                          console.warn(
                                            "O volume a ser utilizado não pode ser maior que o volume disponível."
                                          );
                                        }
                                      }}
                                      style={{ width: "100px" }}
                                      className="form-control"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                        <Col lg={2}>
                          <div className="form-group">
                            <label>Volume total (kg):</label>
                            <InputDecimal0Component
                              placeholder=""
                              value={formatNumberWithThousandSeparator(
                                venda.volumeVenda
                              )}
                              name="volumeVenda"
                              onChange={handleVendaChange}
                              readOnly
                              disabled
                              style={{ color: "#333" }}
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="form-group">
                            <label>
                              Data Carregamento: <small>(de)</small>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="dataCarregamentoDe"
                              value={venda.dataCarregamentoDe}
                              onBlur={handleVendaChange}
                              onChange={(e) =>
                                setVenda({
                                  ...venda,
                                  dataCarregamentoDe: e.target.value,
                                })
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="form-group">
                            <label>
                              Data Carregamento: <small>(até)</small>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="dataCarregamentoAte"
                              value={venda.dataCarregamentoAte}
                              onBlur={handleVendaChange}
                              onChange={(e) =>
                                setVenda({
                                  ...venda,
                                  dataCarregamentoAte: e.target.value,
                                })
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="form-group">
                            <label>Data Pagamento:</label>
                            <input
                              type="date"
                              className="form-control"
                              name="dataPagamento"
                              value={venda.dataPagamento}
                              onBlur={handleVendaChange}
                              onChange={(e) =>
                                setVenda({
                                  ...venda,
                                  dataPagamento: e.target.value,
                                })
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="form-group">
                            <label>Pedida / Saca 60 kg:</label>
                            <InputDecimal2Component
                              placeholder="0,00"
                              value={venda.valorVenda}
                              name="valorVenda"
                              onChange={handleVendaChange}
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="form-group">
                            <label>Destino:</label>
                            <select
                              className="form-control"
                              name="destino"
                              value={venda.destino}
                              onChange={handleVendaChange}
                            >
                              <option value="">Selecione</option>
                              <option value="Interno">Interno</option>
                              <option value="Exportação">Exportação</option>
                              <option value="Indiferente">Indiferente</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="form-group">
                            <label>Este Lote é:</label>
                            <select
                              className="form-control"
                              name="lote"
                              value={venda.lote}
                              onChange={handleVendaChange}
                            >
                              <option value="">Selecione</option>
                              <option value="Firme">Firme</option>
                              <option value="Validação">Validação</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={10} className="text-right mt-4">
                          <Button variant="primary" onClick={handleSubmitVenda}>
                            Salvar Venda
                          </Button>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="lotesNegociacao" title="Lotes em Negociação">
                      <Row>
                        <Col lg={12}>
                          <h5>Lotes em Negociação</h5>
                          <Row>
                            {listaVendas.map((venda) =>
                              venda.negociacao === 1 &&
                              venda.realizada !== 1 ? (
                                <Col lg={4}>
                                  <Card>
                                    <CardBody>
                                      <Badge bg="warning" text="white">
                                        Em Negociação
                                      </Badge>
                                      <Table
                                        striped
                                        bordered
                                        hover
                                        responsive
                                        size="sm"
                                        className="mt-3"
                                      >
                                        <tbody>
                                          <tr>
                                            <td>
                                              Produtor: {venda.nome_usuario}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>{venda.fazenda_nome}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Volume:{" "}
                                              {formatCurrencyBRL(
                                                venda.volume_venda
                                              )}{" "}
                                              (kg)
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {venda.produto} -{" "}
                                              {venda.sub_produto}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>SAFRA {venda.safra}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Pagamento em:{" "}
                                              {formatDateToDDMMYYYY(
                                                venda.data_pagamento
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Pedida de: R${" "}
                                              {formatCurrencyBRL(
                                                venda.valor_venda_bruto
                                              )}{" "}
                                              bruto
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Destino: {venda.destino}</td>
                                          </tr>
                                          <tr>
                                            <td>Este lote é: {venda.lote}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Embarque:{" "}
                                              {formatDateToDDMMYYYY(
                                                venda.data_carregamento_de
                                              )}{" "}
                                              A{" "}
                                              {formatDateToDDMMYYYY(
                                                venda.data_carregamento_ate
                                              )}
                                              .
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="d-grid">
                                              <Button
                                                variant="primary"
                                                size="sm"
                                                className="cemPor"
                                                onClick={() =>
                                                  handleOpenLote(
                                                    venda.id,
                                                    "editar"
                                                  )
                                                }
                                              >
                                                Editar Lote
                                              </Button>
                                              <Button
                                                variant="light"
                                                size="sm"
                                                className="cemPor mt-2"
                                                onClick={() =>
                                                  cancelarLote(venda.id)
                                                }
                                              >
                                                Cancelar Lote #{venda.id}
                                              </Button>
                                              <Button
                                                variant="success"
                                                size="sm"
                                                className="cemPor mt-2"
                                                onClick={() =>
                                                  handleOpenLote(
                                                    venda.id,
                                                    "efetivar"
                                                  )
                                                }
                                              >
                                                Lote Vendido
                                              </Button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </CardBody>
                                  </Card>
                                </Col>
                              ) : null
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab
                      eventKey="vendasDiretas"
                      title="Venda Direta (SEM LOTE)"
                    >
                      <Row>
                        <Col lg={12}>
                          <h5>Venda Direta (SEM LOTE)</h5>

                          <Row>
                            <Col lg={12}>
                              <h5>
                                Vendedor: <b>{nomeUser}</b>
                              </h5>
                              <hr />
                            </Col>
                            <Col lg={4}>
                              <div className="form-group">
                                <label>Fazenda:</label>
                                <select
                                  className="form-control"
                                  name="idFazenda"
                                  value={vendaDireta.idFazenda}
                                  onChange={handleVendaDiretaChange}
                                >
                                  <option value="">Selecione</option>
                                  {fazendas.map((fazenda) => (
                                    <option key={fazenda.id} value={fazenda.id}>
                                      {fazenda.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>

                            <Col lg={3}>
                              <div className="form-group">
                                <label>Produto:</label>
                                <div className="d-flex">
                                  <select
                                    className="form-control"
                                    name="produto"
                                    value={vendaDireta.produto}
                                    onChange={handleVendaDiretaChange}
                                  >
                                    <option value="">Selecione</option>
                                    {produtos.map((produto) => (
                                      <option
                                        key={produto.id}
                                        value={produto.descricao}
                                      >
                                        {produto.descricao}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="form-group">
                                <label>Sub Produto: </label>
                                <div className="d-flex">
                                  <select
                                    className="form-control"
                                    name="subProduto"
                                    value={vendaDireta.subProduto}
                                    onChange={handleVendaDiretaChange}
                                  >
                                    <option value="">Selecione</option>
                                    {subprodutos.map((subproduto) => (
                                      <option
                                        key={subproduto.id}
                                        value={subproduto.descricao}
                                      >
                                        {subproduto.descricao}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div className="form-group">
                                <label>Safra: </label>
                                <select
                                  className="form-control"
                                  name="safra"
                                  value={vendaDireta.safra}
                                  onChange={handleVendaDiretaChange}
                                >
                                  <option value="">Selecione a Safra</option>
                                  <option value="2023/2024">2023/2024</option>
                                  <option value="2024/2025">2024/2025</option>
                                  <option value="2025/2026">2025/2026</option>
                                </select>
                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="form-group">
                                <label>Talhões:</label>
                                <Select
                                  options={talhoesVendaDiretaOptions}
                                  isMulti
                                  placeholder="Selecione"
                                  value={vendaDireta.talhoes}
                                  onChange={handleTalhoesVendaDiretaChange}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <table className="table table-striped mb-4 mt-0">
                                <thead>
                                  <tr>
                                    <th>Nome do talhão</th>
                                    <th>Data da colheita</th>
                                    <th>Volume disponível (kg)</th>
                                    <th>Volume a ser utilizado (kg)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tabelaTalhoesVendaDireta.map(
                                    (talhao, index) => (
                                      <tr key={index}>
                                        <td>{talhao.nome}</td>
                                        <td>
                                          {formatDateToDDMMYYYY(
                                            talhao.data_colheita
                                          )}
                                        </td>
                                        <td>{talhao.volumeDisponivel} kg</td>
                                        <td>
                                          <InputDecimal0Component
                                            max={talhao.volumeDisponivel}
                                            value={formatNumberWithThousandSeparator(
                                              talhao.volumeUtilizado || 0
                                            )}
                                            onChange={(e) => {
                                              const inputValue = Number(
                                                e.target.value
                                                  .replace(/\./g, "")
                                                  .replace(",", ".")
                                              );

                                              function parseToNumber(
                                                value: string
                                              ): number {
                                                return Number(
                                                  value
                                                    .replace(/\./g, "")
                                                    .replace(",", ".")
                                                );
                                              }

                                              console.log(
                                                Number(inputValue),
                                                parseToNumber(
                                                  String(
                                                    talhao.volumeDisponivel
                                                  )
                                                )
                                              );

                                              setAlerta("");

                                              if (
                                                Number(inputValue) <=
                                                parseToNumber(
                                                  String(
                                                    talhao.volumeDisponivel
                                                  )
                                                )
                                              ) {
                                                handleVolumeVendaDiretaChange(
                                                  index,
                                                  inputValue
                                                );
                                              } else {
                                                handleVolumeVendaDiretaChange(
                                                  index,
                                                  0
                                                );
                                                setAlerta(
                                                  "O volume a ser utilizado não pode ser maior que o volume disponível."
                                                );
                                                console.warn(
                                                  "O volume a ser utilizado não pode ser maior que o volume disponível."
                                                );
                                              }
                                            }}
                                            style={{ width: "100px" }}
                                            className="form-control"
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </Col>
                            <Col lg={2}>
                              <div className="form-group">
                                <label>Volume total (kg):</label>
                                <InputDecimal0Component
                                  placeholder=""
                                  value={formatNumberWithThousandSeparator(
                                    vendaDireta.volumeVenda
                                  )}
                                  name="volumeVenda"
                                  onChange={handleVendaDiretaChange}
                                  readOnly
                                  disabled
                                  style={{ color: "#333" }}
                                />
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div className="form-group">
                                <label>
                                  Data Carregamento: <small>(de)</small>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dataCarregamentoDeVendaDireta"
                                  value={vendaDireta.dataCarregamentoDe}
                                  onBlur={handleVendaDiretaChange}
                                  onChange={(e) =>
                                    setVendaDireta({
                                      ...vendaDireta,
                                      dataCarregamentoDe: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div className="form-group">
                                <label>
                                  Data Carregamento: <small>(até)</small>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dataCarregamentoAteVendaDireta"
                                  value={vendaDireta.dataCarregamentoAte}
                                  onBlur={handleVendaDiretaChange}
                                  onChange={(e) =>
                                    setVendaDireta({
                                      ...vendaDireta,
                                      dataCarregamentoAte: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div className="form-group">
                                <label>Data Pagamento:</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dataPagamento"
                                  value={vendaDireta.dataPagamento}
                                  onBlur={handleVendaDiretaChange}
                                  onChange={(e) =>
                                    setVendaDireta({
                                      ...vendaDireta,
                                      dataPagamento: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div className="form-group">
                                <label>Pedida / Saca 60 kg:</label>
                                <InputDecimal2Component
                                  placeholder="0,00"
                                  value={vendaDireta.valorVenda}
                                  name="valorVenda"
                                  onChange={handleVendaDiretaChange}
                                />
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div className="form-group">
                                <label>Destino:</label>
                                <select
                                  className="form-control"
                                  name="destino"
                                  value={vendaDireta.destino}
                                  onChange={handleVendaDiretaChange}
                                >
                                  <option value="">Selecione</option>
                                  <option value="Interno">Interno</option>
                                  <option value="Exportação">Exportação</option>
                                  <option value="Indiferente">
                                    Indiferente
                                  </option>
                                </select>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form-group">
                                <label>Comprador:</label>
                                <div className="d-flex">
                                  <select
                                    className="form-control"
                                    name="cliente"
                                    value={vendaDireta.cliente}
                                    onChange={handleVendaDiretaChange}
                                  >
                                    <option value="">Selecione</option>
                                    {listaClientes.map((cliente: any) => (
                                      <option
                                        key={cliente.id}
                                        value={cliente.id}
                                      >
                                        {cliente.razao_social}
                                      </option>
                                    ))}
                                  </select>
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      marginLeft: "10px",
                                      padding: "0 10px",
                                    }}
                                    onClick={addCliente}
                                  >
                                    <IoAddOutline
                                      style={{
                                        color: "white",
                                        fontSize: "1.5em",
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </Col>
                            <Col lg={6} className="text-right mt-4">
                              <Button
                                variant="primary"
                                onClick={handleSubmitVendaDireta}
                              >
                                Salvar Venda
                              </Button>
                            </Col>
                          </Row>

                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Fazenda</th>
                                <th>Produto</th>
                                <th>Volume (kg)</th>
                                <th>Data Pagamento</th>
                                <th>Valor</th>
                                <th style={{ width: "80px" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {listaVendas.map((venda) =>
                                venda.negociacao !== 1 &&
                                venda.realizada !== 1 ? (
                                  <tr>
                                    <td>{venda.id}</td>
                                    <td>{venda.fazenda_nome}</td>
                                    <td>{venda.produto}</td>
                                    <td>
                                      {formatCurrencyBRL(venda.volume_venda)}
                                    </td>
                                    <td>
                                      {formatDateToDDMMYYYY(
                                        venda.data_pagamento
                                      )}
                                    </td>
                                    <td>
                                      R${" "}
                                      {formatCurrencyBRL(
                                        venda.valor_venda_bruto
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        width: "135px",
                                        display: "flex",
                                      }}
                                    >
                                      <Button
                                        variant="info"
                                        size="sm"
                                        className=""
                                        onClick={() =>
                                          handleOpenLote(
                                            venda.id,
                                            "editar venda direta"
                                          )
                                        }
                                      >
                                        <MdEdit />
                                      </Button>

                                      <Button
                                        variant="danger"
                                        size="sm"
                                        className="ml-2"
                                        onClick={() => cancelarLote(venda.id)}
                                      >
                                        <IoMdTrash />
                                      </Button>
                                    </td>
                                  </tr>
                                ) : null
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="vendasRealizadas" title="Vendas Realizadas">
                      <Row>
                        <Col lg={12}>
                          <h5>Vendas Realizadas</h5>
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Fazenda</th>
                                <th>Produto</th>
                                <th>Volume (kg)</th>
                                <th>Data Pagamento</th>
                                <th>Valor</th>
                                <th style={{ width: "80px" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {listaVendas.map((venda) =>
                                venda.realizada === 1 ? (
                                  <tr>
                                    <td>{venda.id}</td>
                                    <td>{venda.fazenda_nome}</td>
                                    <td>{venda.produto}</td>
                                    <td>{venda.volume_venda}</td>
                                    <td>
                                      {formatDateToDDMMYYYY(
                                        venda.data_pagamento
                                      )}
                                    </td>
                                    <td>
                                      R${" "}
                                      {formatCurrencyBRL(
                                        venda.valor_venda_bruto
                                      )}
                                    </td>
                                    <td style={{ width: "80px" }}>
                                      <Button
                                        variant="info"
                                        size="sm"
                                        className=""
                                        onClick={() =>
                                          handleOpenLote(venda.id, "desfazer")
                                        }
                                      >
                                        <MdEdit />
                                      </Button>
                                    </td>
                                  </tr>
                                ) : null
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="modalCancelaLote">
        <Modal.Header className="text-center">
          <Modal.Title className="cemPor">Cancelar Lote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Deseja realmente cancelar este lote?</h5>
        </Modal.Body>
        <Modal.Footer style={{ margin: "0 auto" }}>
          <Button
            variant="light"
            onClick={handleClose}
            style={{ width: "200px" }}
          >
            Não
          </Button>
          <Button
            variant="primary"
            onClick={() => confirmarCancelarLote()}
            style={{ width: "200px" }}
          >
            Sim cancelar lote
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLote} onHide={handleCloseLote}>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <h5>
                Vendedor: <b>{nomeUser}</b>
              </h5>
              <hr />
            </Col>
            <Col lg={4}>
              <div className="form-group">
                <label>Fazenda:</label>
                <select
                  className="form-control"
                  name="idFazenda"
                  value={vendaModal.idFazenda}
                  onChange={handleVendaModalChange}
                >
                  <option value="">Selecione</option>
                  {fazendas.map((fazenda) => (
                    <option key={fazenda.id} value={fazenda.id}>
                      {fazenda.nome}
                    </option>
                  ))}
                </select>
              </div>
            </Col>

            <Col lg={3}>
              <div className="form-group">
                <label>Produto:</label>
                <div className="d-flex">
                  <select
                    className="form-control"
                    name="produto"
                    value={vendaModal.produto}
                    onChange={handleVendaModalChange}
                  >
                    <option value="">Selecione</option>
                    {produtos.map((produto) => (
                      <option key={produto.id} value={produto.descricao}>
                        {produto.descricao}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </Col>

            <Col lg={3}>
              <div className="form-group">
                <label>Sub Produto: </label>
                <div className="d-flex">
                  <select
                    className="form-control"
                    name="subProduto"
                    value={vendaModal.subProduto}
                    onChange={handleVendaModalChange}
                  >
                    <option value="">Selecione</option>
                    {subprodutos.map((subproduto) => (
                      <option key={subproduto.id} value={subproduto.descricao}>
                        {subproduto.descricao}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </Col>

            <Col lg={2}>
              <div className="form-group">
                <label>Safra: </label>
                <select
                  className="form-control"
                  name="safra"
                  value={vendaModal.safra}
                  onChange={handleVendaModalChange}
                >
                  <option value="">Selecione a Safra</option>
                  <option value="2023/2024">2023/2024</option>
                  <option value="2024/2025">2024/2025</option>
                  <option value="2025/2026">2025/2026</option>
                </select>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <label>Talhões:</label>
                <Select
                  options={talhoesModalOptions}
                  isMulti
                  placeholder="Selecione"
                  value={vendaModal.talhoes}
                  onChange={handleTalhoesModalChange}
                />
              </div>
            </Col>
            <Col lg={12}>
              <table className="table table-striped mb-4 mt-0">
                <thead>
                  <tr>
                    <th>Nome do talhão</th>
                    <th>Data da colheita</th>
                    <th>Volume disponível (kg)</th>
                    <th>Volume a ser utilizado (kg)</th>
                  </tr>
                </thead>
                <tbody>
                  {tabelaTalhoesModal.map((talhao, index) => (
                    <tr key={index}>
                      <td>{talhao.nome}</td>
                      <td>{formatDateToDDMMYYYY(talhao.data_colheita)}</td>
                      <td>{talhao.volumeDisponivel} kg</td>
                      <td>
                        <InputDecimal0Component
                          max={talhao.volumeDisponivel}
                          value={formatNumberWithThousandSeparator(
                            talhao.volumeUtilizado || 0
                          )}
                          onChange={(e) => {
                            const inputValue = Number(
                              e.target.value
                                .replace(/\./g, "")
                                .replace(",", ".")
                            );

                            function parseToNumber(value: string): number {
                              return Number(
                                value.replace(/\./g, "").replace(",", ".")
                              );
                            }

                            console.log(
                              Number(inputValue),
                              parseToNumber(String(talhao.volumeDisponivel))
                            );

                            setAlerta("");

                            if (
                              Number(inputValue) <=
                              parseToNumber(String(talhao.volumeDisponivel))
                            ) {
                              handleVolumeModalChange(index, inputValue);
                            } else {
                              handleVolumeModalChange(index, 0);
                              setAlerta(
                                "O volume a ser utilizado não pode ser maior que o volume disponível."
                              );
                              console.warn(
                                "O volume a ser utilizado não pode ser maior que o volume disponível."
                              );
                            }
                          }}
                          style={{ width: "100px" }}
                          className="form-control"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Volume total (kg):</label>
                <InputDecimal0Component
                  placeholder=""
                  value={formatNumberWithThousandSeparator(
                    vendaModal.volumeVenda
                  )}
                  name="volumeVenda"
                  onChange={handleVendaModalChange}
                  readOnly
                  disabled
                  style={{ color: "#333" }}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>
                  Data Carregamento: <small>(de)</small>
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="dataCarregamentoDe"
                  value={vendaModal.dataCarregamentoDe}
                  onChange={handleVendaModalChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>
                  Data Carregamento: <small>(até)</small>
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="dataCarregamentoAte"
                  value={vendaModal.dataCarregamentoAte}
                  onChange={handleVendaModalChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Data Pagamento:</label>
                <input
                  type="date"
                  className="form-control"
                  name="dataPagamento"
                  value={vendaModal.dataPagamento}
                  onChange={handleVendaModalChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Pedida / Saca 60 kg:</label>
                <InputDecimal2Component
                  placeholder="0,00"
                  value={vendaModal.valorVenda}
                  name="valorVenda"
                  onChange={handleVendaModalChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Destino:</label>
                <select
                  className="form-control"
                  name="destino"
                  value={vendaModal.destino}
                  onChange={handleVendaModalChange}
                >
                  <option value="">Selecione</option>
                  <option value="Interno">Interno</option>
                  <option value="Exportação">Exportação</option>
                  <option value="Indiferente">Indiferente</option>
                </select>
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Este Lote é:</label>
                <select
                  className="form-control"
                  name="lote"
                  value={vendaModal.lote}
                  onChange={handleVendaModalChange}
                >
                  <option value="">Selecione</option>
                  <option value="Firme">Firme</option>
                  <option value="Validação">Validação</option>
                </select>
              </div>
            </Col>
            <Col lg={4}>
              {habilitaClienteModal && (
                <div className="form-group">
                  <label>Comprador:</label>
                  <div className="d-flex">
                    <select
                      className="form-control"
                      name="cliente"
                      value={vendaModal.cliente}
                      onChange={handleVendaModalChange}
                    >
                      <option value="">Selecione</option>
                      {listaClientes.map((cliente: any) => (
                        <option key={cliente.id} value={cliente.id}>
                          {cliente.razao_social}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn btn-primary"
                      style={{
                        marginLeft: "10px",
                        padding: "0 10px",
                      }}
                      onClick={addCliente}
                    >
                      <IoAddOutline
                        style={{
                          color: "white",
                          fontSize: "1.5em",
                        }}
                      />
                    </button>
                  </div>
                </div>
              )}
            </Col>
            <Col lg={6} className="text-right mt-4">
              <Button
                variant="light"
                onClick={handleCloseLote}
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                }}
              >
                Voltar
              </Button>
              {btEditaModalVenda && (
                <Button
                  variant="primary"
                  onClick={() => handleSubmitVendaModal("")}
                  style={{
                    marginLeft: "10px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                  }}
                >
                  Salvar
                </Button>
              )}
              {btEfetivaModalVenda && (
                <Button
                  variant="primary"
                  onClick={() => handleSubmitVendaModal("efetiva")}
                  style={{ marginLeft: "10px" }}
                >
                  Efetivar Venda
                </Button>
              )}
              {desfazerModalVenda && (
                <Button
                  variant="primary"
                  onClick={() => handleSubmitVendaModal("desfazer")}
                  style={{ marginLeft: "10px" }}
                >
                  Desfazer Venda Realizada
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCliente}
        onHide={handleCloseCliente}
        className="modalCliente"
      >
        <Modal.Header>
          <Modal.Title>
            {isEditing ? "Editar Comprador" : "Novo Comprador"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} style={{ display: "none" }}>
              <div className="form-group">
                <label>Tipo:</label>
                <div className="d-flex">
                  <div>
                    <input
                      type="checkbox"
                      className="w-20 h-20 float-left mr-1"
                      name="tipo_cliente"
                      checked={newCliente.tipo_cliente === 1}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label">Comprador</label>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className="form-group">
                <label>Tipo: </label>
                <select
                  className="form-control"
                  name="tipo_pessoa"
                  value={newCliente.tipo_pessoa}
                  onChange={handleCliente}
                >
                  <option value="F">Pessoa Física</option>
                  <option value="J">Pessoa Jurídica</option>
                </select>
              </div>
            </Col>

            <Col lg={8}>
              <div className="form-group">
                <label>{razaoSocialNome}: </label>
                <input
                  type="text"
                  className="form-control"
                  name="razao_social"
                  value={newCliente.razao_social}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCliente}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEditing ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendasPage;
