import Cookies from "js-cookie";

const API_URL = "https://bf-api.solutioweb.com.br/geral";

export const deleteData = async (id: any, tabela: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();
    formData.append("id", id.toString());
    formData.append("tabela", tabela.toString());

    const response = await fetch(API_URL + "/delete", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Falha ao cadastrar a inscricao estadual:", error);
    throw error;
  }
};
