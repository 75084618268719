import Cookies from "js-cookie";

const API_URL = "https://bf-api.solutioweb.com.br/vendas";

export const getVendaById = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error("Erro ao buscar venda: Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar venda:", error);
    throw error;
  }
};

export const getVendasByUser = async (search = "") => {
  try {
    const idUser = Cookies.get("TokenBrasao");
    const url = search
      ? `${API_URL}/lista/${idUser}?search=${encodeURIComponent(search)}`
      : `${API_URL}/lista/${idUser}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Erro ao buscar vendas: Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar vendas:", error);
    throw error;
  }
};

export const createVenda = async (venda: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    if (!token) {
      throw new Error("Token não encontrado. Verifique o cookie.");
    }

    const formData = new FormData();
    formData.append("idUser", token);
    formData.append("idFazenda", venda.idFazenda.toString());
    formData.append("idProducao", JSON.stringify(venda.talhoes));
    formData.append("produto", venda.produto);
    formData.append("subProduto", venda.subProduto);
    formData.append("safra", venda.safra);
    formData.append("volume_venda", venda.volumeVenda.toString());
    formData.append("valor_venda_bruto", venda.valorVenda.toString());
    formData.append("destino", venda.destino);
    formData.append("armazenamento", venda.armazenamento);
    formData.append("data_carregamento_de", venda.dataCarregamentoDe);
    formData.append("data_carregamento_ate", venda.dataCarregamentoAte);
    formData.append("data_pagamento", venda.dataPagamento);
    formData.append("lote", venda.lote);
    formData.append("negociacao", "1");

    const response = await fetch(API_URL, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Erro ao criar venda:", error);
    throw error;
  }
};

export const createVendaDireta = async (venda: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    if (!token) {
      throw new Error("Token não encontrado. Verifique o cookie.");
    }

    const formData = new FormData();
    formData.append("idUser", token);
    formData.append("idFazenda", venda.idFazenda.toString());
    formData.append("idProducao", JSON.stringify(venda.talhoes));
    formData.append("produto", venda.produto);
    formData.append("subProduto", venda.subProduto);
    formData.append("safra", venda.safra);
    formData.append("volume_venda", venda.volumeVenda.toString());
    formData.append("valor_venda_bruto", venda.valorVenda.toString());
    formData.append("destino", venda.destino);
    formData.append("armazenamento", venda.armazenamento);
    formData.append("data_carregamento_de", venda.dataCarregamentoDe);
    formData.append("data_carregamento_ate", venda.dataCarregamentoAte);
    formData.append("data_pagamento", venda.dataPagamento);
    formData.append("lote", venda.lote);
    formData.append("idCliente", venda.cliente);

    const response = await fetch(API_URL, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Erro ao criar venda direta:", error);
    throw error;
  }
};

export const updateVenda = async (venda: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const payload = {
      id: venda.id,
      idUser: token,
      idFazenda: venda.idFazenda,
      idProducao: venda.talhoes,
      produto: venda.produto,
      subProduto: venda.subProduto,
      safra: venda.safra,
      volume_venda: venda.volumeVenda,
      valor_venda_bruto: venda.valorVenda,
      destino: venda.destino,
      armazenamento: venda.armazenamento,
      data_carregamento_de: venda.dataCarregamentoDe,
      data_carregamento_ate: venda.dataCarregamentoAte,
      data_pagamento: venda.dataPagamento,
      lote: venda.lote,
      realizada: venda.realizada,
      idCliente: venda.cliente || null,
    };

    const response = await fetch(`${API_URL}/${venda.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Erro ao atualizar venda");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao atualizar venda:", error);
    throw error;
  }
};

export const deleteVenda = async (id: number) => {
  try {
    if (!id) {
      throw new Error("Id não encontrado. Verifique o cookie.");
    }

    const formData = new FormData();
    formData.append("id", id.toString());

    const response = await fetch(`${API_URL}/delete`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Erro ao excluir venda");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao excluir venda:", error);
    throw error;
  }
};
