import Cookies from "js-cookie";

const API_URL = "https://bf-api.solutioweb.com.br/insumos";

export const getProdutoById = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching conta bancaria:", error);
    throw error;
  }
};

export const getInsumos = async (search = "") => {
  try {
    const url = search
      ? `${API_URL}/lista?search=${encodeURIComponent(search)}`
      : `${API_URL}/lista`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching contas bancarias:", error);
    throw error;
  }
};

export const getSubinsumos = async (search = "") => {
  try {
    const url = search
      ? `${API_URL}/lista-subinsumos?search=${encodeURIComponent(search)}`
      : `${API_URL}/lista-subinsumos`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching contas bancarias:", error);
    throw error;
  }
};

export const createProduto = async (conta: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();

    formData.append("descricao", conta.descricao);

    const response = await fetch(API_URL, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Falha ao cadastrar a conta bancaria:", error);
    throw error;
  }
};

export const updateProduto = async (conta: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();
    formData.append("descricao", conta.descricao);

    const response = await fetch(`${API_URL}/${conta.id}`, {
      method: "PUT",
      body: formData,
    });

    if (!response.ok) {
      return { error: "Houve uma falha. Tente mais tarde." };
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error updating conta bancaria:", error);
    throw error;
  }
};

export const deleteProduto = async (id: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();
    formData.append("id", id.toString());

    const response = await fetch(`${API_URL}/delete`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Falha ao deletar a conta bancaria:", error);
    throw error;
  }
};
